import { coreUtils } from '@trova-trip/trova-common';
import {
    BaseBox,
    Heading,
    Image,
    Skeleton,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import budgetGraph from '../../../../../../../../assets/img/host/home/progress/host-home-progress-budget-graph.svg';
import budgetGraphMobile from '../../../../../../../../assets/img/host/home/progress/host-home-progress-budget-graph-mobile.svg';
import { useHomeProgressContext } from '../../../provider/HomeProgressProvider';
import ReviewBaseStep from './ReviewBaseStep';
import { TrackEventParameters } from '../../../../../../../../analytics/analytics.types';
import { ProgressStepKeys } from '../common/progressStepLayoutConfig';

type Budget = models.hostHomeTab.ReviewResponses['budget'];
const formatCurrency = coreUtils.currencyUtils.formatUSD;

const labels = {
    title: 'Budget',
    description:
        'This one is super important — when you build and request your trip, make it affordable for your audience! Keep the suggested sell price in line with their budget.',
};

interface BudgetContentProps {
    budget?: Budget;
}

const BudgetContent = ({ budget }: BudgetContentProps) => (
    <Stack
        align='center'
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 4, md: 14 }}
    >
        <Image
            src={{ base: budgetGraphMobile, md: budgetGraph }}
            width='30%'
            data-gumlet='false'
        />
        <BaseBox textAlign={{ base: 'center', md: 'left' }}>
            {budget ? (
                <Heading
                    fontSize={{ base: '3xl', md: '5xl' }}
                    fontWeight='bold'
                    lineHeight='base'
                >
                    {formatCurrency(budget)}
                </Heading>
            ) : (
                <Skeleton height={{ base: '16', md: '20' }} width='full' />
            )}
            <Text
                fontSize={{ base: 'base', md: 'lg' }}
                fontStyle='italic'
                color='blueGray.500'
            >
                {"Your traveler's median budget"}
            </Text>
        </BaseBox>
    </Stack>
);

const Budget = () => {
    const progressContext = useHomeProgressContext();
    if (!progressContext) {
        return null;
    }

    const {
        data: {
            reviewData: { data },
        },
    } = progressContext;

    const trackingEvent: TrackEventParameters = {
        eventName: 'Host Reviewed Budget',
        properties: { budget: data?.budget },
    };

    return (
        <ReviewBaseStep
            stepKey={ProgressStepKeys.BUDGET}
            labels={labels}
            customContent={<BudgetContent budget={data?.budget} />}
            trackingEvent={trackingEvent}
        />
    );
};

export default Budget;
