import { useCallback, useMemo, useState } from 'react';
import { ProgressStepViewModel } from '../types';

interface StepsState {
    steps: ProgressStepViewModel[] | undefined;
    currentStep: { index: number | null; subIndex: number | null };
}
export interface UseStepsManagerReturn {
    completeStep: () => void;
    goToNextStep: () => void;
    setCurrentStep: (stepId: ProgressStepViewModel['id']) => void;
    getCurrentStep: (
        step?: ProgressStepViewModel,
    ) => ProgressStepViewModel | undefined;
    setSteps: (steps: StepsState['steps']) => void;
    currentStep: ProgressStepViewModel | undefined;
    steps: StepsState['steps'];
}

/** Finds an step index and subIndex inside of a tree like structure of steps with nested steps
 * @param stepId id of the step that needs to be found in the structure.
 * @param steps Steps Tree Structure to preform the search into.
 */
const findStepIndex = (
    stepId: string,
    steps: ProgressStepViewModel[] | undefined,
): StepsState['currentStep'] => {
    let index: number | null = null;
    let subIndex: number | null = null;
    if (!steps) {
        return { index, subIndex };
    }
    for (let i = 0; i < steps.length; i++) {
        const currentStep = steps[i];
        if (currentStep.id === stepId) {
            index = i;
            break;
        } else if (currentStep.steps?.length) {
            const indexes = findStepIndex(stepId, currentStep.steps);
            if (indexes.index !== null) {
                index = i;
                subIndex = indexes.index;
                break;
            }
        }
    }
    return { index, subIndex };
};

const unlockNextSubstep = (
    steps: ProgressStepViewModel[],
    indexes: { index: number; subIndex: number },
) => {
    const { index, subIndex } = indexes;
    const subSteps = steps[index].steps;
    if (subSteps) {
        subSteps[subIndex + 1] = {
            ...subSteps[subIndex + 1],
            locked: false,
        };
    }
};

const unlockNextStep = (steps: ProgressStepViewModel[], index: number) => {
    steps[index + 1] = {
        ...steps[index + 1],
        locked: false,
    };
};

const completeCurrentStep = (
    steps: ProgressStepViewModel[],
    indexes: { index: number; subIndex: number | null },
    partial: boolean,
) => {
    const { index, subIndex } = indexes;
    const subSteps = steps[index].steps;
    steps[index] = {
        ...steps[index],
        completed:
            partial && subSteps && subIndex !== null
                ? (1 / subSteps.length) * (subIndex + 1)
                : 1,
    };
};

const completeCurrentSubstep = (
    steps: ProgressStepViewModel[],
    indexes: { index: number; subIndex: number },
) => {
    const { index, subIndex } = indexes;
    const subSteps = steps[index].steps;
    if (subSteps) {
        subSteps[subIndex] = { ...subSteps[subIndex], completed: 1 };
    }
};

/** Custom hook for handling operations on the Host Progress Steps Viewmodel Data structure
 * @param initialSteps Steps Model to initialize the hook inner state.
 */
const useProgressStepsManager = (
    initialSteps: ProgressStepViewModel[] | undefined,
): UseStepsManagerReturn => {
    const [stepsState, setStepsState] = useState<StepsState>({
        steps: initialSteps,
        currentStep: { index: null, subIndex: null },
    });

    const selectedStep = useMemo(() => {
        const {
            steps,
            currentStep: { index, subIndex },
        } = stepsState;
        if (index === null || !steps) {
            return;
        }
        return subIndex !== null
            ? steps[index].steps?.[subIndex]
            : steps[index];
    }, [stepsState]);

    const setSteps = (steps: StepsState['steps'] | undefined) => {
        setStepsState((prev) => ({ ...prev, steps: steps }));
    };

    const setIndexes = (index: number, subIndex: number | null) => {
        setStepsState((prev) => ({
            ...prev,
            currentStep: { index, subIndex },
        }));
    };

    const setCurrentStep = useCallback(
        (stepId: ProgressStepViewModel['id']) => {
            const stepIndex = findStepIndex(stepId, stepsState.steps);
            if (stepIndex.index === null) return;
            setStepsState((prev) => ({ ...prev, currentStep: stepIndex }));
        },
        [stepsState.steps],
    );

    const goToNextStep = useCallback(() => {
        const {
            steps,
            currentStep: { index, subIndex },
        } = stepsState;

        if (index === null || !steps) {
            return;
        }
        const subSteps = steps[index].steps;
        const hasNextSubStep = subIndex !== null && !!subSteps?.[subIndex + 1];
        if (hasNextSubStep && subIndex !== null) {
            const nextSubIndex = subIndex + 1;
            setIndexes(index, nextSubIndex);
        } else {
            const nextStepIndex = index + 1;
            const nextStepSubIndex = steps[nextStepIndex].steps?.length
                ? 0
                : null;
            setIndexes(nextStepIndex, nextStepSubIndex);
        }
    }, [stepsState]);

    const completeStep = useCallback(() => {
        const {
            steps,
            currentStep: { index, subIndex },
        } = stepsState;

        if (index === null || !steps) {
            return;
        }
        const stepsCopy = steps.slice();
        if (subIndex !== null) {
            const subSteps = stepsCopy[index].steps as ProgressStepViewModel[];
            const hasNextSubStep = !!subSteps[subIndex + 1];
            completeCurrentSubstep(stepsCopy, { index, subIndex });
            completeCurrentStep(stepsCopy, { index, subIndex }, hasNextSubStep);
            if (hasNextSubStep) {
                unlockNextSubstep(stepsCopy, { index, subIndex });
            } else {
                unlockNextStep(stepsCopy, index);
            }
        } else {
            completeCurrentStep(stepsCopy, { index, subIndex }, false);
            unlockNextStep(stepsCopy, index);
        }
        setSteps(stepsCopy);
        goToNextStep();
    }, [stepsState, goToNextStep, selectedStep]);

    const getCurrentStep = useCallback(
        (step?: ProgressStepViewModel) => {
            const stepList = step?.steps ?? stepsState.steps;
            if (!stepList) {
                return;
            }
            let currentStep: ProgressStepViewModel | undefined;
            for (const step of stepList) {
                if (step.steps) {
                    const substep = step.steps.find(
                        (substep) => !substep.completed,
                    );
                    if (substep) {
                        currentStep = substep;
                        break;
                    }
                } else {
                    if (!step.completed) {
                        currentStep = step;
                        break;
                    }
                }
            }
            return currentStep;
        },
        [stepsState.steps],
    );

    return {
        steps: stepsState.steps,
        currentStep: selectedStep,
        setSteps,
        setCurrentStep,
        getCurrentStep,
        completeStep,
        goToNextStep,
    };
};

export default useProgressStepsManager;
