import reviewResponsesImage from '../../../../../../../../assets/img/host/home/progress/locked/review-responses.svg';
import BlockStep from '../common/BlockStep';
const labels = {
    title: 'Review Your Survey Data Here',
    description:
        'Continue collecting survey responses. This data will help you decide where to travel first.',
};

const ReviewResponses = () => {
    return <BlockStep {...labels} imagePath={reviewResponsesImage} />;
};

export default ReviewResponses;
