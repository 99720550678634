import {
    BaseBox,
    Heading,
    Hide,
    Progression,
    ProgressionProps,
} from '@trova-trip/trova-components';
import { useHomeProgressContext } from '../provider/HomeProgressProvider';
import { ProgressSkeleton } from './skeletons/Skeletons';

const ProgressTree = () => {
    const progressContext = useHomeProgressContext();
    if (!progressContext) {
        return null;
    }

    const {
        steps: { currentStep, onStepSelect, steps },
        data: {
            progressData: { isLoading },
        },
    } = progressContext;

    return (
        <BaseBox
            backgroundColor={{ base: 'neutral.white', md: 'transparent' }}
            marginX={{ base: -4, md: 0 }}
            padding={{ base: 2, md: 0 }}
            boxShadow={{ base: '4', md: 'none' }}
        >
            {isLoading ? (
                <ProgressSkeleton />
            ) : (
                <>
                    <Hide breakpoint={{ min: 'md' }}>
                        <Heading
                            color={'blueGray.500'}
                            fontSize='xxs'
                            fontWeight='regular'
                            lineHeight='normal'
                            marginBottom={1}
                        >
                            HOST PROGRESS
                        </Heading>
                    </Hide>
                    <Progression
                        ariaLabel='host-home-progression'
                        stepsModel={steps as ProgressionProps['stepsModel']}
                        onSelect={onStepSelect}
                        activeStepId={currentStep?.id}
                    />
                </>
            )}
        </BaseBox>
    );
};

export default ProgressTree;
