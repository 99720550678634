import { SidebarItemProps } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';

type Availability = models.itineraries.Availability;

/**
 * Helper functions for instantApproval label on trips and itineraries
 */
const getInstantApprovalLabel = (
    instantApprovalAllowed: boolean,
): SidebarItemProps['labelItems'] =>
    instantApprovalAllowed
        ? [
              {
                  leftIconProps: {
                      as: 'checkedCircle',
                      color: 'alerts.success.icon',
                  },
                  label: 'Instant Approval',
              },
          ]
        : [];

const isInstantApprovalAllowed = (packageAvailability: Availability) => {
    return !!packageAvailability.instantApprovalDates.length;
};

export { getInstantApprovalLabel, isInstantApprovalAllowed };
