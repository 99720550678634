import ProductProps from '../../applications/ApplicationProps';
import React, { useEffect } from 'react';
import { businessConstants } from '@trova-trip/trova-common';
import Application from '../../navigation/Application';
import ItinerariesCatalog from './products/itinerariesCatalog/ItinerariesCatalog';
import Trips from './products/trips/Trips';
import Audience from './products/audience/Audience';
import useModelStatusMatcher from '../common/hooks/useModelStatusMatcher';
import ContentForModelStatus from '../common/components/ContentForModelStatus';
import { IconName } from '@trova-trip/trova-components';
import { useSelector } from 'react-redux';
import {
    userTripRequests,
    UserTripRequestStoreSelector,
} from '../../state/userTripRequests';
import Onboarding from './products/onboarding/Onboarding';
import {
    isPublicHostProfileEnabled,
    UserStatuses,
} from '../../config/constants';
import Referral from './products/referral/Referral';
import Home from './products/home/Home';
import { userHosts, UserHostStoreSelector } from '../../state/hosts';

import Transfer from '../common/products/transfer';
import Account from '../common/products/account';
import Loading from '../../components/Loading';
import { StatefulModel } from '../types';
import { models } from '@trova-trip/trova-models';
import useLoadNavigationContent from './hooks/useLoadNavigationContent';
import PublicProfile from './products/publicProfile/PublicProfile';

type TripRequest = models.tripRequest.TripRequest;
type Host = models.hosts.Host;

const { QualifiedHostStatuses, PlanningHostStatuses } = businessConstants;

const shouldShowOnboardingStatuses = new Set([
    UserStatuses.CREATED,
    UserStatuses.READY_TO_QUALIFY,
    UserStatuses.QUALIFYING,
    UserStatuses.QUALIFIED,
]);

export const isHomeDashboardEnabled = Boolean(
    process.env.REACT_APP_HOME_DASHBOARD,
);
export const isHomeProgressEnabled = Boolean(
    process.env.REACT_APP_HOME_PROGRESS,
);

const checkIfLoading = (
    currentHost: UserHostStoreSelector['userHosts'],
    tripRequests: TripRequest[] | undefined,
) => {
    const isHostNotLoaded =
        currentHost.fetching || currentHost.current === undefined;

    const isTripRequestsNotLoaded = tripRequests === undefined;

    return isHostNotLoaded || isTripRequestsNotLoaded;
};

interface ContentProps extends ProductProps {
    isLoading: boolean;
    isHostQualified: boolean;
    currentHost: {
        current: Host;
        fetching?: boolean | undefined;
    };
    tripRequests: TripRequest[] | undefined;
}

const Content = ({
    profile,
    isLoading,
    isHostQualified,
    currentHost,
    tripRequests,
}: ContentProps) => {
    const { listRecords: getTripRequests } = userTripRequests.useDispatch();

    useEffect(() => {
        getTripRequests();
    }, []);

    const shouldShowOnboarding = useModelStatusMatcher({
        model: StatefulModel.PROFILE,
        matchingStatuses: shouldShowOnboardingStatuses,
    });

    const [navigationContent, refreshSidebarContent] =
        useLoadNavigationContent();

    if (!profile) {
        return null;
    }

    const hasTripRequests = Boolean(tripRequests?.length);
    const showHostDashboard = hasTripRequests && isHostQualified;
    const host: Host | null = currentHost.current;
    const isOnboardingCompleted = !!(host && host.onboardingCompleted);

    const showHostOnboardingProduct =
        (host === null || !isOnboardingCompleted) &&
        shouldShowOnboarding;

    const showHomeProduct = showHostDashboard
        ? isHomeDashboardEnabled
        : isHomeProgressEnabled;

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : showHostOnboardingProduct ? (
                <Onboarding profile={profile} host={host} />
            ) : (
                <>
                    {showHomeProduct ? (
                        <Home showDashboard={showHostDashboard} />
                    ) : null}
                    <ContentForModelStatus
                        model={StatefulModel.PROFILE}
                        matchingStatuses={PlanningHostStatuses}
                        render={(): JSX.Element => (
                            <Trips navigationContent={navigationContent} />
                        )}
                        forceRender={hasTripRequests}
                    />
                    <Audience isHostQualified={isHostQualified} />
                    <ItinerariesCatalog
                        qualifiedSidebarContent={navigationContent}
                        refreshSidebarContent={refreshSidebarContent}
                    />
                    <Account />
                    <Transfer
                        loadNavigationContent={() => navigationContent || []}
                    />
                    <Referral />
                    {isPublicHostProfileEnabled ? <PublicProfile /> : null}
                </>
            )}
        </>
    );
};

export const Host = ({ profile }: ProductProps) => {
    const { getRecord: getCurrentHost } = userHosts.useDispatch();

    const currentHost = useSelector(
        (state: UserHostStoreSelector) => state.userHosts,
    );
    const tripRequests = useSelector(
        (state: UserTripRequestStoreSelector) =>
            state.userTripRequests.list?.records,
    );

    useEffect(() => {
        getCurrentHost();
    }, []);

    const isLoading = checkIfLoading(currentHost, tripRequests);
    const isHostQualified = useModelStatusMatcher({
        model: StatefulModel.PROFILE,
        matchingStatuses: new Set([
            ...QualifiedHostStatuses,
            ...PlanningHostStatuses,
        ]),
    });

    const sidebarProps = isHostQualified
        ? {
              buttonPath: '/app/host/itineraries',
              buttonText: 'Request a Trip',
              type: 'hostQualified',
          }
        : {
              buttonIcon: IconName.RightArrow,
              buttonPath: '/app/host/audience/research',
              buttonText: 'Start Surveying',
              type: 'hostNonQualified',
          };

    return (
        <Application
            profile={profile}
            path='/host'
            sidebarProps={sidebarProps}
            pageLoading={isLoading}
        >
            <Content
                profile={profile}
                isLoading={isLoading}
                isHostQualified={isHostQualified}
                currentHost={currentHost}
                tripRequests={tripRequests}
            />
        </Application>
    );
};

export default Host;
