import { useState } from 'react';
import {
    Stack,
    Image,
    Modal,
    IconButton,
    Heading,
    Loader,
    BaseBox,
    theme,
} from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import surveyTimelineMobile from '../../../../../../../assets/img/host/home/progress/survey-resources/survey-timeline-mobile.svg';
import surveyTimelineDesktop from '../../../../../../../assets/img/host/home/progress/survey-resources/survey-timeline-desktop.svg';

interface SurveyTimelineModalProps {
    isOpen: boolean;
    isMobile: boolean;
    onCloseClick: () => void;
}

const SurveyTimelineModal = ({
    isOpen,
    isMobile,
    onCloseClick,
}: SurveyTimelineModalProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <Modal
            isOpen={isOpen}
            isCentered={{ base: false, md: true }}
            marginY={{ base: 0, md: 3.75 }}
        >
            <Card
                padding={0}
                borderRadius={{ base: 0, md: 'xl' }}
                maxHeight='100vh'
            >
                <Stack
                    paddingX={6}
                    paddingY={4}
                    wrap='nowrap'
                    width='full'
                    justify='space-between'
                    align='center'
                    borderBottom='1px solid'
                    borderColor='blueGray.200'
                >
                    <Heading as='h4' fontSize={{ base: 'lg', md: 'xl' }}>
                        Survey Timeline{' '}
                    </Heading>
                    <IconButton
                        icon='close'
                        size='md'
                        aria-label='Close dialog'
                        variant='ghost'
                        onClick={onCloseClick}
                    />
                </Stack>
                {!isImageLoaded ? (
                    <BaseBox
                        width={{ base: '100vw', md: '4xl' }}
                        height={{ base: '90vh', md: '2xl' }}
                        textAlign='center'
                        alignContent='center'
                    >
                        <Loader size={100} color={theme.colors.teal.trova} />
                    </BaseBox>
                ) : null}
                <Image
                    onLoad={() => setIsImageLoaded(true)}
                    width='4xl'
                    data-gumlet='false'
                    src={
                        isMobile ? surveyTimelineMobile : surveyTimelineDesktop
                    }
                />
            </Card>
        </Modal>
    );
};

export default SurveyTimelineModal;
