import { BaseBox, Heading, Hide } from '@trova-trip/trova-components';
import { useSelector } from '../../../../../state/hooks';
import ProgressWidget from './components/ProgressWidget';
import { HomeProgressProvider } from './provider/HomeProgressProvider';
import SurveyResources from '../common/components/SurveyResources/SurveyResources';

const HomeProgress = () => {
    const host = useSelector((state) => state.profile.current);
    return (
        <HomeProgressProvider>
            <Hide breakpoint={{ max: 'md' }}>
                <BaseBox marginBottom={2}>
                    <Heading size='3xl'>Hello, {host?.firstName}</Heading>
                </BaseBox>
            </Hide>
            <ProgressWidget />
            <BaseBox marginTop={{ base: 4, md: 6 }}>
                <SurveyResources />
            </BaseBox>
        </HomeProgressProvider>
    );
};

export default HomeProgress;
