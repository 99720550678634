import {
    BaseBox,
    Stack,
    Text,
    Video,
    VideoProps,
    Heading,
    HeadingProps,
    useReveal,
    useWebShare,
} from '@trova-trip/trova-components';
import {
    Card,
    Icon,
    Button,
    ButtonProps,
} from '@trova-trip/trova-components/build/next';
import { useState } from 'react';
import { HostResurveyStatuses } from '../../../../../../../../config/constants';
import { useSelector } from '../../../../../../../../state/hooks';
import { User } from '../../../../../../../types';
import useGetUserLinks from '../../../../../../../../util/hooks/useGetUserLinks';
import ProgressStep from '../common/ProgressStep';
import surveyVideoImage from '../../../../../../../../assets/img/host/home/progress/host-survey-image.png';
import surveyVideoImageMobile from '../../../../../../../../assets/img/host/home/progress/host-survey-image-mobile.png';
import { useHomeProgressContext } from '../../../provider/HomeProgressProvider';
import CopyTextButton from '../../../../../../../../components/CopyTextButton';
import useIsLargeScreen from '../../../../../../../common/hooks/useIsLargeScreen';
import { CopyTextButtonProps } from '../../../../../../../../components/CopyTextButton/CopyTextButton';
import {
    isPublicHostProfileEnabled,
    TROVA_HOME_SURVEY_VIDEO,
} from '../../../../../../../../config/constants';
import { useTrackingEvent } from '../../../../../../../../analytics/hooks';
import CTAWrapper from '../common/CTAWrapper';
import { ProgressStepKey } from '../common/progressStepLayoutConfig';
import { usePublicProfileUrl } from '../../../../../../hooks/usePublicProfileUrl';
import SharePublicHostProfileDialog from '../../../../../../components/SharePublicHostProfileDialog/SharePublicHostProfileDialog';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_TRACKING_EVENTS,
    PUBLIC_PROFILE_DIALOG_LOCATION,
} from '../../../../../../hooks/usePublicProfileAnalytics';
import { constants } from '@trova-trip/trova-models';

const { itinerary: { TierSurveysRequired }, host: {HostHomeProgressStepKey}} = constants;

const labels = {
    [HostHomeProgressStepKey.START_SURVEYING]: {
        title: 'Survey Your Audience',
        description:
            `To collect survey responses, share your profile link with your community to learn key insights about their  travel preferences. A minimum of ${TierSurveysRequired[1]} qualified survey responses are required to plan a trip.`,
    },
    [HostHomeProgressStepKey.FIFTY_QUALIFIED_RESPONSES]: {
        title: `Get ${TierSurveysRequired[1]}+ Qualified Responses`,
        description:
            `Share your link on social media, email lists, and blogs. ${TierSurveysRequired[1]} responses unlocks Starter itineraries, but collecting ${TierSurveysRequired[2]}+ responses gets you access to double the itinerary options and a dedicated Trova rep to help you along the way.`,
    },
};

interface SurveyHeaderProps {
    onClick: () => void;
}

const SurveyHeader = ({ onClick }: SurveyHeaderProps) => {
    const isLargeScreen = useIsLargeScreen({ includeTabletResolution: true });
    return (
        <Card
            cursor='pointer'
            onClick={onClick}
            width='full'
            height={{ base: '40', md: '56' }}
            borderBottomRadius={{ base: 'none', md: '2xl' }}
            overflow='hidden'
            backgroundImage={{
                base: surveyVideoImageMobile,
                md: surveyVideoImage,
            }}
            css={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPositionY: isLargeScreen ? '-30px' : '0',
                backgroundOrigin: 'border-box',
            }}
        >
            <Stack
                justify='space-between'
                width='full'
                align='center'
                marginBottom={2}
                wrap='nowrap'
            >
                <Heading
                    lineHeight='none'
                    color='neutral.white'
                    fontWeight='bold'
                    fontSize={{ base: 'lg', md: '3xl' }}
                >
                    How To Survey Your Audience
                </Heading>
                <BaseBox
                    borderRadius='base'
                    paddingX={{ base: 1, md: 2 }}
                    paddingY={{ base: 1, md: 1.5 }}
                    border='1px'
                    borderColor='neutral.white'
                >
                    <Text
                        fontWeight='bold'
                        lineHeight='none'
                        color='neutral.white'
                        fontSize={{ base: 'xs', md: 'base' }}
                        textTransform='capitalize'
                    >
                        VIDEO
                    </Text>
                </BaseBox>
            </Stack>
            <Icon
                color='neutral.white'
                as='playCircle'
                variant='filled'
                size={{ base: 'xl', md: 'xxl' }}
            />
        </Card>
    );
};

interface SurveyBaseStepProps {
    stepKey: ProgressStepKey;
}

const largeScreenIcons: Record<
    'copyButton' | 'previewButton' | 'shareProfileButton' | 'viewProfileButton',
    ButtonProps['rightIcon']
> = {
    copyButton: 'copy',
    previewButton: 'eye',
    shareProfileButton: 'share',
    viewProfileButton: 'offsite',
};

const titleProps: Omit<HeadingProps, 'children'> = {
    paddingX: { base: 3, md: 0 },
    marginTop: 6,
    fontSize: { base: 'xl', md: '4xl' },
};

const SurveyBaseStep = ({ stepKey }: SurveyBaseStepProps) => {
    const userLinks = useGetUserLinks();
    const progressContext = useHomeProgressContext();
    const { trackUserEvent } = useTrackingEvent();
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();
    const isLargeScreen = useIsLargeScreen({ includeTabletResolution: true });
    const { isOpen, triggerClose, triggerOpen } = useReveal();
    const { share } = useWebShare();
    const publicProfileUrl = usePublicProfileUrl();
    const [showVideo, setShowVideo] = useState<boolean>(false);
    const { status } = useSelector((state) => {
        return state.profile.current as User;
    });
    const selectedLabels = labels[stepKey];
    if (!progressContext || !selectedLabels) {
        return null;
    }
 
    const isResurvey = HostResurveyStatuses.has(status);  
    const {
        steps: { onCompleteButtonClick: completeStep },
    } = progressContext;

    const onHeaderClick = () => {
        setShowVideo(true);
    };

    const onCopyClick = () => {
        completeStartSurveyingStep();
        trackUserEvent({ eventName: 'Host Copied Survey (Home)' });
    };

    const videoEventHandlers: VideoProps['eventHandlers'] = {
        onPlay: () =>
            trackUserEvent({
                eventName: 'Host Watched How to Survey Video',
            }),
    };

    const shareProfile = () => {
        const data = {
            title: 'Public Profile URL',
            text: publicProfileUrl,
        };

        trackPublicProfileEvent(PUBLIC_PROFILE_TRACKING_EVENTS.shareProfile, {
            location: PUBLIC_PROFILE_DIALOG_LOCATION.homeChecklist,
        });

        // When in mobile, use the native webshare functionality if it's supported
        if (!isLargeScreen && !isResurvey) {
            // share takes a fallback function as its second param, in the case webshare isn't supported
            return share(data, triggerOpen);
        }

        triggerOpen();
    };

    const onCloseShareProfile = () => {
        triggerClose();
        completeStartSurveyingStep();
    };

    const completeStartSurveyingStep = () => {
        if (stepKey === HostHomeProgressStepKey.START_SURVEYING) {
            completeStep();
        }
    };

    const viewProfileButtonProps: Partial<ButtonProps> & { label: string } = {
        as: 'a',
        linkProps: {
            href: publicProfileUrl,
            target: '_blank',
        },
        rightIcon: isLargeScreen
            ? largeScreenIcons.viewProfileButton
            : undefined,
        label: 'View Public Profile',
    };

    const previewSurveyProps: Partial<ButtonProps> & {
        label: string;
    } = {
        as: 'a',
        linkProps: {
            href: userLinks.surveyLink,
            target: '_blank',
        },
        rightIcon: isLargeScreen ? largeScreenIcons.previewButton : undefined,
        label: 'Preview Survey',
    };

    const baseButtonProps = {
        isFullWidth: { base: true, md: false },
        marginLeft: { base: 3, md: 0 },
    };

    const shareProfileButtonProps = {
        ...baseButtonProps,
        rightIcon: isLargeScreen
            ? largeScreenIcons.shareProfileButton
            : undefined,
        onClick: shareProfile,
    };

    const copyButtonProps: Partial<CopyTextButtonProps> = {
        ...baseButtonProps,
        textToCopy: userLinks.surveyLink || '',
        onCopyTitle: 'Link Copied',
        onCopyMessage: 'Share on your social networks',
        onClick: onCopyClick,
        icon: isLargeScreen ? largeScreenIcons.copyButton : false,
        size: 'md' as ButtonProps['size'],
    };

    return (
        <>
            <Video
                src={TROVA_HOME_SURVEY_VIDEO}
                variant='modal'
                borderRadius={{ md: 'xl' }}
                isOpen={showVideo}
                onClose={() => setShowVideo(false)}
                options={{ autoplay: true }}
                eventHandlers={videoEventHandlers}
            />
            <ProgressStep
                stepKey={stepKey}
                content={<SurveyHeader onClick={onHeaderClick} />}
                title={
                    <Heading lineHeight='normal' {...titleProps}>
                        {selectedLabels.title}
                    </Heading>
                }
                descriptionProps={{
                    description: (
                        <Text
                            fontSize={{ base: 'sm', md: 'base' }}
                            marginTop={4}
                        >
                            {selectedLabels.description}
                        </Text>
                    ),
                    containerProps: { paddingX: { base: 3, md: 0 } },
                }}
                footer={
                    <CTAWrapper
                        containerProps={{ marginRight: { base: 3, md: 0 } }}
                        primaryCustomCTA={
                            isPublicHostProfileEnabled ? (
                                <Button {...shareProfileButtonProps}>
                                    Share Profile
                                </Button>
                            ) : (
                                <CopyTextButton {...copyButtonProps}>
                                    Copy Survey Link
                                </CopyTextButton>
                            )
                        }
                        secondaryCTAProps={
                            isPublicHostProfileEnabled
                                ? viewProfileButtonProps
                                : previewSurveyProps
                        }
                    />
                }
            />
            {isPublicHostProfileEnabled ? (
                <SharePublicHostProfileDialog
                    isOpen={isOpen}
                    onClose={onCloseShareProfile}
                    location={PUBLIC_PROFILE_DIALOG_LOCATION.homeChecklist}
                    message='Collect survey responses, grow your audience, and promote upcoming trips.'
                    link={publicProfileUrl}
                />
            ) : null}
        </>
    );
};

export default SurveyBaseStep;
