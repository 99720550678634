import PropTypes from 'prop-types';
import { Text, BaseBox, Heading } from '@trova-trip/trova-components';

const TabDetailsInformation = ({ title, description, className = '' }) => {
    return (
        <div className={className}>
            <Heading as={'h2'}>{title}</Heading>
            {description && (
                <BaseBox paddingTop={4} paddingBottom={8}>
                    <Text>{description} </Text>
                </BaseBox>
            )}
        </div>
    );
};
TabDetailsInformation.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
};

export default TabDetailsInformation;
