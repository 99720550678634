import React, { useCallback, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Icon, IconName, theme } from '@trova-trip/trova-components';
import PropTypes from 'prop-types';

import Product from '../../../navigation/Product';
import { userItinerary } from '../../../state/userItinerary';
import { createNavigationCategory } from '../../../util/navigationUtils';
import { ItineraryStatuses } from '../../../config/constants';
import ReviewDetails from '../tabs/ReviewDetails/ReviewDetails';
import ConfigureItineraryServices from '../tabs/ConfigureService/ConfigureItineraryServices';
import ItineraryReviewPricing from '../tabs/ReviewPricing/Itinerary/ItineraryReviewPricing';
import headerImage from '../../../assets/img/trovatrip-arial-isaland-2.jpg';
import TabDetailsInformation from '../../../components/TabDetailsInformation/TabDetailsInformation';
import { redirectToTab } from '../tabs/utils/RedirectToTab';
import SuccessMessage from '../tabs/SuccessMessage/SuccessMessage';
import { getHeaderDataFromItinerary } from '../../utils';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { ProductHeader } from 'applications/common/components/ProductHeader';
import ProductRootWithMobileNavigationView from 'applications/common/components/ProductRootWithMobileNavigationView';
import { getInstantApprovalLabel } from '../../../applications/common/helpers';
/*
Categories:
    - not submitted
    - Pending approval
    - Live
 */

const createSortableItineraries = (itineraryRecords) => {
    const notSubmitted = createNavigationCategory(`Not Submitted`);
    const pendingApproval = createNavigationCategory(`Pending Approval`);
    const live = createNavigationCategory(`Live`);

    const createItineraryNavigationItem = (
        id,
        name,
        priority = 1,
        packages,
    ) => {
        const instantApprovalAllowed = Object.keys(packages || {}).some(
            (currentPackage) =>
                packages[currentPackage]?.validityPeriods?.some(
                    (elem) => elem?.instantApprovalAllowed,
                ),
        );

        const labelItems = getInstantApprovalLabel(instantApprovalAllowed);

        return {
            id,
            title: name,
            priority,
            labelItems,
        };
    };

    itineraryRecords.forEach(({ status, id, name, packages }) => {
        if (
            status === ItineraryStatuses.CREATED ||
            status === ItineraryStatuses.INCOMPLETE
        ) {
            notSubmitted.items.push(
                createItineraryNavigationItem(id, name, 1, packages),
            );
        }
        if (status === ItineraryStatuses.IN_REVIEW) {
            pendingApproval.items.push(
                createItineraryNavigationItem(id, name, 2, packages),
            );
        }

        if (status === ItineraryStatuses.COMPLETE) {
            live.items.push(
                createItineraryNavigationItem(id, name, 3, packages),
            );
        }
    });

    return [notSubmitted, pendingApproval, live].filter(
        ({ items }) => items.length,
    );
};

const sortItinerariesByPriority = (itineraries) =>
    itineraries.sort(
        (sidebarA, sidebarB) => sidebarA.priority - sidebarB.priority,
    );

const formatNavigationContent = (itineraryRecords) => {
    const sortableItineraries = createSortableItineraries(itineraryRecords);
    return sortableItineraries;
};

const useGetLoadNavigationContent = () => {
    const { listRecords: getItineraries } = userItinerary.useDispatch();

    const loadNavigationContent = useCallback(async () => {
        const itinerariesResult = await new Promise((resolve) => {
            getItineraries({}, { successCallback: resolve });
        });

        return formatNavigationContent(itinerariesResult.records);
    }, [getItineraries]);

    return loadNavigationContent;
};

const useGetRootItinerary = (setRootItinerary) => {
    const loadNavigationContent = useGetLoadNavigationContent();

    return useCallback(async () => {
        const sidebarContent = await loadNavigationContent();
        const prioritizedItineraries =
            sortItinerariesByPriority(sidebarContent);
        const defaultItinerary =
            prioritizedItineraries &&
            prioritizedItineraries.length > 0 &&
            prioritizedItineraries[0].items[0];
        setRootItinerary(defaultItinerary);
    }, [setRootItinerary, loadNavigationContent]);
};
const RootItinerary = () => {
    const [rootItinerary, setRootItinerary] = useState();
    const getRootItinerary = useGetRootItinerary(setRootItinerary);
    getRootItinerary();
    return rootItinerary ? (
        <Redirect to={`itineraries/${rootItinerary.id}/review-details`} />
    ) : (
        <TabDetailsInformation
            title='Welcome!'
            description='Just click on Create Itinerary to start the process!'
        />
    );
};

const shouldTabBeDisabled = (history) => {
    const { location } = history;
    return location.pathname.indexOf(`new`) > 1;
};

const Itineraries = () => {
    const loadNavigationContent = useGetLoadNavigationContent();
    const itinerary = useSelector((state) => state.userItinerary.current);
    const history = useHistory();

    const [error, setError] = useState();
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });
    const {
        getRecord: fetchItinerary,
        createRecord: createItinerary,
        updateRecord: updateItinerary,
        clearCurrentRecord: clearItinerary,
    } = userItinerary.useDispatch();

    const getItinerary = useCallback(
        (id) => fetchItinerary(id),
        [fetchItinerary],
    );

    const {
        itineraryName,
        itineraryHeaderPhoto,
        itineraryCountry,
        itineraryDestination,
    } = getHeaderDataFromItinerary(itinerary, headerImage);

    const onConfigureServicesNextButtonClick = (id, data) => {
        updateItinerary(id, data, {
            successCallback: () => {
                setError(undefined);
                redirectToTab(history, `review-pricing`);
            },

            errorCallback: (error) => {
                setError(`Error - ${error}`);
            },
        });
    };

    const cleanErrors = () => {
        setError(null);
    };

    const clearModelData = () => {
        clearItinerary();
    };

    const renderSuccess = () => {
        setSnackBar({
            message: 'Your changes have been saved!',
            color: 'success',
            show: true,
        });
    };

    const isTabDisabled = shouldTabBeDisabled(history);

    return (
        <Product
            path={`/itineraries`}
            label={`Itineraries`}
            icon='route'
            fetchModelData={getItinerary}
            loadNavigationContent={loadNavigationContent}
            clearModelData={clearItinerary}
            header={
                itineraryName && (
                    <ProductHeader
                        backgroundImage={itineraryHeaderPhoto}
                        title={itineraryName}
                        icon={
                            <Icon
                                name={IconName.Location}
                                color={theme.colors.red[400]}
                            />
                        }
                        iconDescription={`${itineraryCountry}, ${itineraryDestination}`}
                    />
                )
            }
            root={
                <ProductRootWithMobileNavigationView
                    rootView={<RootItinerary />}
                />
            }
        >
            <ReviewDetails
                data={itinerary || {}}
                saveModelData={createItinerary}
                updateModelData={updateItinerary}
                disabled={false}
            />
            <ConfigureItineraryServices
                data={itinerary}
                renderError={setError}
                renderSuccess={renderSuccess}
                updateModelData={updateItinerary}
                onNextButtonClick={onConfigureServicesNextButtonClick}
                cleanErrors={cleanErrors}
                errorText={error}
                disabled={isTabDisabled}
            />
            <ItineraryReviewPricing
                data={itinerary}
                saveModelData={createItinerary}
                updateModelData={updateItinerary}
                renderError={setError}
                cleanErrors={cleanErrors}
                errorText={error}
                disabled={isTabDisabled}
            />
            <SuccessMessage
                title={`Done!`}
                subtitle={`Your Itinerary has been successfully created!`}
                onClickButton={() => redirectToTab(history, `review-details`)}
                buttonMessage={`Go back to Review Details`}
            />
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={3000}
                onClose={() => {
                    setSnackBar({
                        message: '',
                        color: 'success',
                        show: false,
                    });
                }}
            />
        </Product>
    );
};

Itineraries.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
    }),
};

export default Itineraries;
