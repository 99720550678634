import { PricingCalculator } from '@trova-trip/trova-common';
import { BaseBox, Heading, Stack, Text } from '@trova-trip/trova-components';
import {
    Icon,
    IconProps,
    SelectableCard,
} from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import { formatCurrency } from '../../../../applications/utils';
import { useTripRequestPricing } from '../../hooks/useTripRequestPricing';
import {
    AvailabilityViewModel,
    FormInputNames,
    InitialTripRequestData,
    PackageLevel,
} from './TripRequestForm.types';
import { checkIsInstantApprovalAvailable } from './tripRequestFormHelper';

type PackageProps = models.itineraries.ItineraryPackage;
const packageLevels = constants.itinerary.PackageLevel;

interface TripRequestPackageCardItem {
    iconName: IconProps['as'];
    label: string;
    color?: string;
}

const TripRequestPackageCardItem = ({
    iconName,
    label,
    color = 'blueGray.650',
}: TripRequestPackageCardItem): JSX.Element => (
    <Stack
        direction='row'
        width='full'
        flex='1'
        wrap='nowrap'
        marginBottom='0.5'
    >
        <BaseBox marginTop='0.5'>
            <Icon as={iconName} color={color} />
        </BaseBox>
        <Text as='span' fontSize='sm'>
            {label}
        </Text>
    </Stack>
);

interface TripRequestPackageCardHeaderProps {
    packageLevel: PackageLevel;
    tripPrice: string;
}

const TripRequestPackageCardHeader = ({
    packageLevel,
    tripPrice,
}: TripRequestPackageCardHeaderProps): JSX.Element => {
    const packageTitle = `${packageLevel
        .charAt(0)
        .toLocaleUpperCase()}${packageLevel.slice(1)}`;

    return (
        <Stack
            direction='row'
            width='full'
            align='center'
            justify='space-between'
            marginBottom='2'
        >
            <Heading as='h6' fontSize='lg' fontWeight='medium'>
                {packageTitle}
            </Heading>
            <Heading as='h6' fontSize='lg' fontWeight='medium'>
                {tripPrice}
            </Heading>
        </Stack>
    );
};

interface TripRequestPackageCardProps {
    packageLevel: PackageLevel;
    isDisabled?: boolean;
    isSelected: boolean;
    onPackageSelected: (packageName: PackageLevel) => void;
    mealLevels: PackageProps['mealLevels'];
    foodOptions: PackageProps['foodOptions'];
    accommodationLevels: PackageProps['accommodationLevels'];
    travelersTierNumber: number;
    itinerary: InitialTripRequestData['itinerary'];
    tripRequestCostDTO: Omit<PricingCalculator.TripRequestCostDTO, 'host'>;
    packageAvailability: AvailabilityViewModel;
}

const TripRequestPackageCard = ({
    packageLevel,
    isSelected,
    isDisabled,
    onPackageSelected,
    itinerary,
    travelersTierNumber,
    mealLevels,
    foodOptions,
    accommodationLevels,
    tripRequestCostDTO,
    packageAvailability,
}: TripRequestPackageCardProps): JSX.Element => {
    const rawTripPrice = useTripRequestPricing({
        itinerary,
        tripRequestCostDTO: {
            ...tripRequestCostDTO,
            [FormInputNames.SELECTED_PACKAGE]: packageLevel,
        },
        travelersTierNumber,
        packageAvailability,
    });
    const tripPrice = formatCurrency(rawTripPrice);

    const packageItems: TripRequestPackageCardItem[] = [
        {
            iconName: 'hotelStar',
            label: `${accommodationLevels[0]} Star Accommodation`,
        },
        {
            iconName: 'foodBank',
            label: mealLevels[0],
        },
    ];

    // For now, we only want to show instant approval bolt for standard package
    if (
        packageLevel === packageLevels.STANDARD &&
        checkIsInstantApprovalAvailable(packageAvailability, itinerary)
    ) {
        packageItems.unshift({
            iconName: 'bolt',
            color: 'teal.trova',
            label: 'Instant Approval Available',
        });
    }

    if (foodOptions.length > 0) {
        packageItems.push({
            iconName: 'localDining',
            label: foodOptions.join(', '),
        });
    }

    return (
        <SelectableCard
            key={packageLevel}
            onClick={(): void => onPackageSelected(packageLevel)}
            isDisabled={isDisabled}
            isSelected={isSelected}
            css={{ flex: 1 }}
        >
            <TripRequestPackageCardHeader
                packageLevel={packageLevel}
                tripPrice={tripPrice}
            />
            {packageItems.map((elem) => (
                <TripRequestPackageCardItem {...elem} key={elem.label} />
            ))}
        </SelectableCard>
    );
};

export default TripRequestPackageCard;
