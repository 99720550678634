import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { models } from '@trova-trip/trova-models';
import Tab from '../../../../navigation/Tab';
import TabDetailsInformation from '../../../../components/TabDetailsInformation/TabDetailsInformation';
import CreateRequestForm from 'applications/host/components/TripRequestForm/CreateRequestForm';
import TripPricingCard from '../../components/TripPricingCard/TripPricingCard';
import { ItineraryStoreSelector } from '../../../../state/itineraries';
import { MainContent } from '@trova-trip/trova-components/build/next';
import { isInventoryEnabled } from '../../../../applications/common/helpers';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

interface TripRequestProps {
    refreshSidebarContent: () => void;
}

const TripRequest = ({
    refreshSidebarContent,
}: TripRequestProps): JSX.Element => {
    const [fetchingPrice, setFetchingPrice] = useState<boolean>();
    const [estimatedPrice, setEstimatedPrice] = useState<SuggestedSellPrice>();

    const {
        userItinerary: { current: currentItinerary },
    } = useSelector((state: ItineraryStoreSelector) => state);

    const isInventoriedItinerary = isInventoryEnabled(currentItinerary);

    const tabDescription = isInventoriedItinerary
        ? 'Changes can no longer be accepted after submitting'
        : 'Please note that your trip request will be sent to our Operating partners ' +
          'for review and approval — changes cannot be made after you submit a trip ' +
          'request so please double check the details!';

    const { maximumSpots = 20 } =
        useSelector(
            (state: ItineraryStoreSelector) => state.userItinerary.current,
        ) || {};

    const [travelersTierNumber, setTravelersTierNumber] =
        useState(maximumSpots);

    useEffect(() => {
        if (!currentItinerary) {
            setEstimatedPrice(undefined);
        }
    }, [currentItinerary]);

    useEffect(() => {
        setTravelersTierNumber(maximumSpots);
    }, [maximumSpots]);

    return (
        <Tab disabled label={'Trip Request'} path={'/request'} hideNavigation>
            <MainContent.Inner
                marginTop={12}
                sidebarElement={
                    <TripPricingCard
                        title='Suggested Sell Price'
                        value={estimatedPrice}
                        isLoading={fetchingPrice}
                    />
                }
                sidebarConfig={{
                    offsetTop: 5,
                }}
            >
                <Grid container>
                    <Grid item xs={12} sm={10} md={10} lg={9}>
                        <TabDetailsInformation
                            title={'Submit Your Trip Request'}
                            description={tabDescription}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CreateRequestForm
                            onChangePrice={(price, fetching): void => {
                                setFetchingPrice(fetching);
                                setEstimatedPrice(price);
                            }}
                            travelersTierNumber={travelersTierNumber}
                            refreshSidebarContent={refreshSidebarContent}
                        />
                    </Grid>
                </Grid>
            </MainContent.Inner>
        </Tab>
    );
};

export default TripRequest;
