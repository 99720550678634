import { StepViewProps } from '../../../types';
import LockedRequestTrip from '../locked/RequestTrip';
import useHostRecommendations from '../../../../../../hooks/useHostRecommendations';
import HomeRecommendedItineraries from './HomeRecommendedItineraries';
import BrowseItineraries from './BrowseItineraries';

const RequestTrip = ({ isLocked }: StepViewProps): JSX.Element => {
    const { recommendations, getItineraryRecommendation } =
        useHostRecommendations();

    if (isLocked) {
        return <LockedRequestTrip />;
    }

    return recommendations ? (
        <HomeRecommendedItineraries
            recommendations={recommendations}
            getItineraryRecommendation={getItineraryRecommendation}
        />
    ) : (
        <BrowseItineraries />
    );
};

export default RequestTrip;
