import { constants, models } from '@trova-trip/trova-models';
import isSameDay from 'date-fns/isSameDay';
import cloneDeep from 'lodash/cloneDeep';
import {
    checkIfPackageIsAvailable,
    findInventoryItemByStartDate,
    findInventoryItemsByPackage,
    getAccommodationServices,
    getAirportTransferServices,
    getStartDatesOfInventoryItems,
    isInstantApprovalAllowed,
    isInventoryEnabled,
} from '../../../../applications/common/helpers';
import { ServiceTypesKeys } from '../../../../config/constants';
import {
    AvailabilityByPackage,
    AvailabilityViewModel,
    AvailabilityViewModelByPackage,
    HostSelectedOptionalService,
    InitialFormData,
    InitialTripRequestData,
    PackageAvailability,
} from './TripRequestForm.types';

type Companion = models.common.Companion;
type HostRoom = models.tripRequest.HostRoom;
type ServicesByDay = models.services.ServicesByDay;
type DayService = models.services.DayService;
type Service = models.services.Service;
type TripRequest = models.tripRequest.TripRequest;
type Itinerary = models.itineraries.Itinerary;
const ServiceType = constants.services.ServiceType;
type Availability = models.itineraries.Availability;
type ItineraryPackageLevels = models.itineraries.ItineraryPackageLevels;
type PackageLevel = constants.itinerary.PackageLevel;
type HostSelectedOptionalServices = models.trips.HostSelectedOptionalServices;
type PrePostAccommodationsSelection =
    models.tripRequest.PrePostAccommodationsSelection;
type PrePostTransfersSelection = models.tripRequest.PrePostTransfersSelection;
type SavedItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;

const packageLevel = constants.itinerary.PackageLevel;

const getPrePostAccommodationsInitialValues = (
    accommodations: HostSelectedOptionalServices[],
): PrePostAccommodationsSelection => {
    const remappedAccommodations = accommodations.map(
        ({ activity }) => activity,
    );
    const { postTripAccommodationServices, preTripAccommodationsServices } =
        getAccommodationServices(remappedAccommodations as Service[]);

    return {
        beforeTrip: !!preTripAccommodationsServices.length,
        afterTrip: !!postTripAccommodationServices.length,
    };
};

const getPrePosTransfersInitialValues = (
    transfers: HostSelectedOptionalServices[],
): PrePostTransfersSelection => {
    const remappedTransfers = transfers.map(({ activity }) => activity);
    const { preTripTransferServices, postTripTransferServices } =
        getAirportTransferServices(remappedTransfers as Service[]);

    return {
        beforeTrip: !!preTripTransferServices.length,
        afterTrip: !!postTripTransferServices.length,
    };
};

/**
 * Returns the initial package to be selected by a defined priority only
 * if the package is enabled.
 *
 * @param availability
 * @returns {PackageLevel}
 */
const getInitialSelectedPackage = (
    availability: AvailabilityViewModelByPackage,
): PackageLevel => {
    const priorities = [
        packageLevel.STANDARD,
        packageLevel.ECONOMY,
        packageLevel.PREMIUM,
    ];

    const selectedPackage = priorities.find((priority) => {
        const currentAvailability = availability[priority];
        return currentAvailability?.isPackageEnabled;
    });

    return selectedPackage || priorities[0];
};

/**
 * Returns the initial form data for the creation of a new trip request.
 *
 * @param currentItinerary
 * @param availabilityByPackage
 * @returns {InitialTripRequestData}
 */
export const getInitialTripRequestDataFromItinerary = (
    currentItinerary: Itinerary,
    availabilityByPackage: AvailabilityByPackage,
): InitialTripRequestData => {
    const { startingPrice, servicesByDay } = currentItinerary;

    const availabilityViewModel = prepareAvailabilityViewModel(
        currentItinerary,
        availabilityByPackage,
    );

    const selectedPackage = getInitialSelectedPackage(availabilityViewModel);

    const { firstAvailableDate = '', itineraryInventoryItems = [] } =
        availabilityViewModel[selectedPackage] || {};

    const inventoryItem = findInventoryItemByStartDate(
        itineraryInventoryItems,
        firstAvailableDate,
    );

    const initialFormData = {
        basePrice: startingPrice as number,
        tripLength: (servicesByDay as ServicesByDay).length,
        startDate: firstAvailableDate as string,
        itineraryInventoryItemId: inventoryItem?._id,
        quantityHostRooms: 1,
        hostSelectedOptionalServices: [],
        additionalRequests: '',
        selectedWorkshopSpaces: [],
        companions: [],
        hostRooms: [
            {
                roomType: constants.tripRequest.HostRoomType.ONE_BED,
            },
        ],
        selectedPackage,
        dietaryRestriction: constants.itinerary.PackageFoodOptions.NONE,
        prePostAccommodationsSelection: {
            afterTrip: false,
            beforeTrip: false,
        },
        prePostTransfersSelection: {
            afterTrip: false,
            beforeTrip: false,
        },
    };
    return { initialFormData, itinerary: currentItinerary };
};

export const prepareTripRequestUpdateAvailability = (
    availability: AvailabilityByPackage,
    selectedPackage: PackageLevel,
    selectedInventoryItem?: SavedItineraryInventoryItem,
): AvailabilityByPackage => {
    if (!selectedInventoryItem) {
        return availability;
    }

    const availabilityClone = cloneDeep(availability);

    if (!availabilityClone[selectedPackage]) {
        availabilityClone[selectedPackage] = {
            firstAvailableDate: '',
            lastAvailableDate: '',
            instantApprovalDates: [],
            blackoutDates: [],
            itineraryInventoryItems: [selectedInventoryItem],
        };
        return availabilityClone;
    }

    const { itineraryInventoryItems = [] } = availabilityClone[selectedPackage];

    const isSelectedItemAvailable = itineraryInventoryItems.some(
        (item) => item._id === selectedInventoryItem._id,
    );

    if (!isSelectedItemAvailable) {
        availabilityClone[selectedPackage].itineraryInventoryItems.push(
            selectedInventoryItem,
        );
    }

    return availabilityClone;
};

export const getInitialTripRequestDataFromTripRequest = (
    currentTripRequest: TripRequest,
    itineraryInventoryItem?: SavedItineraryInventoryItem,
): InitialTripRequestData => {
    const {
        startingPrice,
        itinerary,
        tripLeadTime,
        additionalRequests,
        quantityHostRooms,
        startDate,
        hostSelectedOptionalServices,
        selectedWorkshopSpaces,
        companions,
        hostRooms,
        selectedPackage,
        dietaryRestriction,
    } = currentTripRequest;

    const workshops =
        itinerary.servicesByDay?.flatMap((day: DayService[]) =>
            day.filter(({ service }) => {
                const actualService = service as Service;
                return actualService.type === ServiceTypesKeys.WORKSHOP_SPACE;
            }),
        ) || [];

    const hostSelectedOptionalServicesValue = hostSelectedOptionalServices?.map(
        (item) => {
            return {
                id: item.activity?.id,
                numberOptingIn: item.numberOptingIn,
                service: item.activity,
            };
        },
    );

    const selectedWorkshopSpacesValue = selectedWorkshopSpaces?.map((item) => {
        const serviceIndex = workshops.findIndex((dayService) => {
            return (dayService.service as Service)._id === item.activity?.id;
        });

        const service = workshops[serviceIndex]?.service;

        return {
            id: item.activity?.id,
            length: item.length,
            service,
        };
    });

    const prePostAccommodationsSelection =
        getPrePostAccommodationsInitialValues(hostSelectedOptionalServices);

    const prePostTransfersSelection = getPrePosTransfersInitialValues(
        hostSelectedOptionalServices,
    );

    const initialFormData = {
        basePrice: startingPrice,
        tripLength: tripLeadTime,
        startDate: startDate,
        quantityHostRooms,
        itineraryInventoryItemId: itineraryInventoryItem?._id,
        hostSelectedOptionalServices: hostSelectedOptionalServicesValue || [],
        additionalRequests,
        selectedWorkshopSpaces: selectedWorkshopSpacesValue || [],
        companions,
        hostRooms,
        selectedPackage,
        dietaryRestriction,
        prePostAccommodationsSelection,
        prePostTransfersSelection,
    };

    return {
        initialFormData,
        itinerary: currentTripRequest.itinerary,
    };
};

export const removeOldService = (
    oldServiceId: string | undefined,
    currentData: InitialFormData,
) => {
    return oldServiceId
        ? currentData.hostSelectedOptionalServices.filter(
              (service) => service.id !== oldServiceId,
          )
        : currentData.hostSelectedOptionalServices;
};

export const addService = (
    services: HostSelectedOptionalService[],
    service: Service,
    companions: Companion[],
    hostRooms: HostRoom[],
): HostSelectedOptionalService[] => {
    const newService: HostSelectedOptionalService = {
        id: service._id!,
        service: service,
        numberOptingIn:
            service.type === ServiceType.AIRPORT_TRANSFER
                ? companions.length + 1
                : hostRooms.length,
    };
    return services.concat([newService]);
};

export const removeService = (
    services: HostSelectedOptionalService[],
    id: string | undefined,
): HostSelectedOptionalService[] => {
    return services.filter((newService) => newService.id !== id);
};

export const getPricedServices = (services: Service[]): Service[] => {
    return services?.filter(({ price = 0 }) => price > 0);
};

export const isInstantApproval = (
    instantApprovalDates: Date[] | string[],
    selectedDate: Date,
): boolean => {
    const normalizedDate = new Date(selectedDate);

    return instantApprovalDates.some((instantDate) => {
        const normalizedInstantDate = new Date(instantDate);
        return isSameDay(normalizedDate, normalizedInstantDate);
    });
};

// Fix discrepancy with timezones/dates (only when not in edit mode)
export const initializeCalendarDate = (
    date: string | undefined,
    editMode: boolean,
): Date | undefined => {
    if (!date) {
        return undefined;
    }
    const dateObj = new Date(date);

    if (!editMode && dateObj.getUTCDate() !== dateObj.getDate()) {
        return new Date(stripTimezone(date));
    }

    return dateObj;
};

// Strip the 'Z' which indicates timezone from an ISO string, if any
export const stripTimezone = (dateString: string) => {
    return new Date(dateString.replace(/Z/g, ''));
};

/**
 *
 * this helper function validates if the user selects a package and a date with
 * instant approval allowed
 */
export const shouldValidateInstantApproval = (
    selectedPackage: PackageLevel,
    isInstantApprovalDate: boolean,
    itineraryAvailability: Availability,
): boolean => {
    // TODO: once we have instant approval allowed for all packages,
    // we can remove this check only for standard
    const shouldCheckValidityPeriods =
        selectedPackage === packageLevel.STANDARD && isInstantApprovalDate;

    return (
        shouldCheckValidityPeriods &&
        isInstantApprovalAllowed(itineraryAvailability)
    );
};

export const checkIsInstantApprovalAvailable = (
    availability: AvailabilityViewModel | undefined,
    itinerary: Itinerary,
): boolean => {
    if (!availability) {
        return false;
    }
    if (!isInstantApprovalAllowed(availability)) {
        return false;
    }
    return itinerary.trovaAutoPriced || availability.isInventoryEnabled;
};

const transformAvailabilityToAvailabilityViewModel = (
    packageName: PackageLevel,
    availability: PackageAvailability,
    itinerary: Itinerary,
): AvailabilityViewModel => {
    const withInventory = isInventoryEnabled(itinerary);
    const items = availability.itineraryInventoryItems || [];

    const enabledDates = withInventory
        ? getStartDatesOfInventoryItems(items, 'ISOString')
        : [];

    let instantApprovalDates = availability.instantApprovalDates;
    if (withInventory) {
        const standardItems = findInventoryItemsByPackage(
            items,
            packageLevel.STANDARD,
        );
        instantApprovalDates = getStartDatesOfInventoryItems(
            standardItems,
            'ISOString',
        );
    }

    const itineraryPackage = itinerary.packages[packageName];
    const isPackageEnabled = checkIfPackageIsAvailable(
        itineraryPackage,
        availability,
        withInventory,
    );

    return {
        ...availability,
        instantApprovalDates,
        isInventoryEnabled: withInventory,
        enabledDates,
        isPackageEnabled,
    };
};

export const prepareAvailabilityViewModel = (
    itinerary: Itinerary,
    availabilityByPackage: AvailabilityByPackage,
): AvailabilityViewModelByPackage => {
    const viewModel = Object.keys(availabilityByPackage).reduce(
        (acc, packageName: PackageLevel) => {
            const currentAvailability = availabilityByPackage[packageName];

            acc[packageName] = transformAvailabilityToAvailabilityViewModel(
                packageName,
                currentAvailability,
                itinerary,
            );

            return acc;
        },
        {} as AvailabilityViewModelByPackage,
    );
    return viewModel;
};
