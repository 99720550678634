import {
    BaseBoxProps,
    Grid,
    GridItemProps,
} from '@trova-trip/trova-components';
import React from 'react';
import {
    ProgressStepKey,
    STEP_MAPPER,
    TEMPLATE_AREAS,
} from './progressStepLayoutConfig';

interface ProgressStepProps {
    containerProps?: BaseBoxProps;
    content?: React.ReactNode;
    title?: React.ReactNode;
    descriptionProps?: {
        description?: React.ReactNode;
        containerProps?: GridItemProps;
    };
    footer?: React.ReactNode;
    stepKey: ProgressStepKey;
}

const ProgressStep = ({
    containerProps,
    content,
    descriptionProps,
    title,
    footer,
    stepKey,
}: ProgressStepProps) => {
    const template = STEP_MAPPER[stepKey];
    return (
        <Grid
            templateAreas={template}
            templateColumns='1fr'
            width='full'
            gap={0.5}
            {...containerProps}
        >
            {content ? (
                <Grid.Item area={TEMPLATE_AREAS.content}>{content}</Grid.Item>
            ) : null}
            <Grid.Item area={TEMPLATE_AREAS.title}>{title}</Grid.Item>
            {descriptionProps?.description ? (
                <Grid.Item
                    area={TEMPLATE_AREAS.description}
                    {...descriptionProps?.containerProps}
                >
                    {descriptionProps?.description}
                </Grid.Item>
            ) : null}
            {footer ? (
                <Grid.Item area={TEMPLATE_AREAS.footer}>{footer}</Grid.Item>
            ) : null}
        </Grid>
    );
};

export default ProgressStep;
