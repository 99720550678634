import { constants, models } from '@trova-trip/trova-models';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../state/hooks';
import {
    HostProgressSteps,
    HostProgressStepValues,
    HostResponsesReview,
    updateHostProgress,
} from '../../../../../../state/hosts';
import { WidgetNames } from '../../common/types';
import useHomeWidgetData, {
    LoadResponse,
    UseHomeWidgetDataReturn,
} from '../../dashboard/hooks/useHomeWidgetData';
import RequestTrip from '../components/steps/RequestTrip';
import Budget from '../components/steps/review/Budget';
import TripDates from '../components/steps/review/TripDates';
import TripDestinations from '../components/steps/review/TripDestinations';
import SurveyBaseStep from '../components/steps/survey/SurveyBaseStep';
import { ProgressStepViewModel } from '../types';
import ReviewResponses from '../components/steps/review/ReviewResponses';
import { ProgressStepKeys } from '../components/steps/common/progressStepLayoutConfig';

const { HostHomeProgressStepKey } = constants.host;
type HostHomeProgressStepKey = constants.host.HostHomeProgressStepKey;
type HomeProgress = models.hosts.HostHomeProgressResponse;

export interface UseHomeProgressDataReturn {
    progressData: Omit<
        UseHomeWidgetDataReturn<ProgressStepViewModel[]>,
        'load'
    >;
    reviewData: Omit<UseHomeWidgetDataReturn<HostResponsesReview>, 'load'>;
    loadProgress: () => LoadResponse;
    loadReviews: () => LoadResponse;
    updateProgress: (progressStep: ProgressStepViewModel) => LoadResponse;
}

const StepsNamesAliases = {
    [HostHomeProgressStepKey.SURVEY_YOUR_AUDIENCE]: 'Survey',
    [HostHomeProgressStepKey.REVIEW_RESPONSES]: 'Review',
    [HostHomeProgressStepKey.REQUEST_TRIP]: 'Request',
    [HostHomeProgressStepKey.TRAVEL_DATES]: 'Dates',
};

const ProgressViewComponents = {
    [HostHomeProgressStepKey.START_SURVEYING]: SurveyBaseStep.bind(null, {
        stepKey: ProgressStepKeys.START_SURVEYING,
    }),
    [HostHomeProgressStepKey.FIFTY_QUALIFIED_RESPONSES]: SurveyBaseStep.bind(
        null,
        {
            stepKey: HostHomeProgressStepKey.FIFTY_QUALIFIED_RESPONSES,
        },
    ),
    [HostHomeProgressStepKey.DESTINATIONS]: TripDestinations,
    [HostHomeProgressStepKey.TRAVEL_DATES]: TripDates,
    [HostHomeProgressStepKey.BUDGET]: Budget,
    [HostHomeProgressStepKey.REQUEST_TRIP]: RequestTrip,
    [HostHomeProgressStepKey.REVIEW_RESPONSES]: ReviewResponses,
};

const buildProgressViewModel = (
    progressData: HostProgressSteps | undefined,
): ProgressStepViewModel[] | undefined => {
    if (!progressData) {
        return;
    }

    return progressData.map((progressStep, index, arr) => {
        const previousStep = arr[index - 1];
        return {
            id: progressStep.key as HostHomeProgressStepKey,
            name: StepsNamesAliases[progressStep.key] ?? progressStep.name,
            completed: progressStep.percent,
            locked: previousStep ? !(previousStep.percent === 1) : false,
            ViewComponent:
                ProgressViewComponents[progressStep.key] ?? (() => null),
            serverValue: progressStep.value as HostProgressStepValues,
            steps: buildProgressViewModel(progressStep.steps),
        };
    });
};

const useHomeProgressData = (): UseHomeProgressDataReturn => {
    const { current: currentUser } = useSelector((state) => state.profile);

    const { load: loadProgress, ...progressData } =
        useHomeWidgetData<HomeProgress>(WidgetNames.PROGRESS);
    const { load: loadReviews, ...reviewData } =
        useHomeWidgetData<HostResponsesReview>(WidgetNames.REVIEWS);

    const updateProgress = async (
        progressStep: ProgressStepViewModel,
    ): Promise<{ success: boolean }> => {
        const response = await updateHostProgress(currentUser?.id as string, {
            homeProgress: progressStep.serverValue,
        });
        return { success: !!response?.success };
    };

    const progressDataViewModel = useMemo(
        () => buildProgressViewModel(progressData.data?.steps),
        [progressData.data],
    );

    return {
        progressData: { ...progressData, data: progressDataViewModel },
        reviewData,
        loadProgress,
        updateProgress,
        loadReviews,
    };
};

export default useHomeProgressData;
