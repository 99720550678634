import { constants } from '@trova-trip/trova-models';
import { useSelector } from 'state/hooks';

const {
    itinerary: { TIER },
    user: { HOST_QUALIFIED_STATUSES },
} = constants;

export const useGetHostDefaultTiers = (nextTier: string | null): string[] => {
    const { current: host } = useSelector((state) => state.profile);
    const tiers = Object.keys(TIER);

    if (host?.status && !HOST_QUALIFIED_STATUSES.includes(host.status)) {
        return nextTier ? [TIER.Tier1.toString()] : tiers;
    }

    const hostTiers = tiers.filter((tier) =>
        host?.itineraryTier ? host.itineraryTier >= Number(tier) : false,
    );

    return nextTier ? [nextTier] : hostTiers;
};
