import {
    Heading,
    BaseBox,
    Text,
    HeadingProps,
} from '@trova-trip/trova-components';
import RecommendationBannerWrapper from '../../../../../../components/RecommendationBanner/RecommendationBannerWrapper';
import { AdminRecommendationLocations } from '../../../../../../../host/utils/trackingEvents.utils';
import { ProgressStepKeys } from '../common/progressStepLayoutConfig';
import { UseHostRecommendationsReturn } from '../../../../../../../host/hooks/useHostRecommendations';
import ProgressStep from '../common/ProgressStep';

const copy = {
    title: 'Recommended Itineraries',
    description:
        "We've hand selected these itineraries based on your survey data and audience travel preferences.",
};

const titleProps: HeadingProps = {
    fontSize: { base: 'xl', md: '4xl' },
    as: 'h3',
    lineHeight: 'none',
    marginBottom: { base: 0, md: 3 },
    marginX: 3,
    marginTop: { base: 3, md: 0 },
    children: copy.title,
};

interface HomeRecommendedItinerariesProps
    extends Pick<
        UseHostRecommendationsReturn,
        'recommendations' | 'getItineraryRecommendation'
    > {}

const HomeRecommendedItineraries = ({
    recommendations,
    getItineraryRecommendation,
}: HomeRecommendedItinerariesProps): JSX.Element => (
    <ProgressStep
        stepKey={ProgressStepKeys.REQUEST_TRIP_RECOMMENDATIONS}
        containerProps={{
            marginX: 3,
            marginBottom: { base: 0, md: 3 },
        }}
        title={<Heading {...titleProps} />}
        descriptionProps={{
            description: (
                <Text fontSize={{ base: 'sm', md: 'base' }} marginTop={4}>
                    {copy.description}
                </Text>
            ),
        }}
        content={
            <BaseBox
                paddingX={{ base: 3, md: 0 }}
                paddingTop={{ base: 3, md: 0 }}
            >
                <RecommendationBannerWrapper
                    trackingDataLocation={AdminRecommendationLocations.HOME}
                    layoutType='home'
                    recommendations={recommendations}
                    getItineraryRecommendation={getItineraryRecommendation}
                />
            </BaseBox>
        }
    />
);

export default HomeRecommendedItineraries;
