import { validationSchemas } from '@trova-trip/trova-models';
import * as yup from 'yup';
import { FieldName, FieldPrefix, PackageLevel } from './constants';
import { AccommodationPrice } from './types';

const {
    validationMessages: { requiredMessage },
} = validationSchemas;

const {
    CreateItineraryInventoryItemInputSchema: CreateSchema,
    UpdateItineraryInventoryItemInputSchema: UpdateSchema,
    accommodationPriceSchema: AccommodationSchema,
} = validationSchemas;

const withSanitizedNumber = (schema: yup.BaseSchema): yup.BaseSchema => {
    const sanitizedSchema = schema.transform((val, orig) =>
        orig == '' ? undefined : val,
    );
    return sanitizedSchema;
};

const sanitizedAccommodationSchema: yup.SchemaOf<AccommodationPrice> = yup
    .object()
    .shape({
        single: withSanitizedNumber(AccommodationSchema.fields.single),
        double: withSanitizedNumber(AccommodationSchema.fields.double),
    })
    .optional();

const generateValidationSchema = (action: 'create' | 'update') => {
    const Schema = action === 'create' ? CreateSchema : UpdateSchema;

    const { preTransferPrice, postTransferPrice } = Schema.fields;

    const newSchemaShape = {
        [FieldName.Id]: yup.string().optional(),
        [FieldName.PackageLevel]: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string().oneOf(Object.values(PackageLevel)),
            })
            .nullable()
            .required(requiredMessage('Package')),
        [FieldName.PreTransferPrice]: withSanitizedNumber(preTransferPrice),
        [FieldName.PostTransferPrice]: withSanitizedNumber(postTransferPrice),
        [FieldName.PreAccPrice]: sanitizedAccommodationSchema,
        [FieldName.PostAccPrice]: sanitizedAccommodationSchema,
    };

    const schema = Schema.omit([
        FieldName.PackageLevel,
        FieldName.PreTransferPrice,
        FieldName.PostTransferPrice,
        FieldName.PreAccPrice,
        FieldName.PostAccPrice,
    ]).concat(yup.object().shape(newSchemaShape));

    return yup.object().shape({
        [FieldPrefix]: yup.object().concat(schema),
    });
};

const generateCreateValidationSchema = () => {
    return generateValidationSchema('create');
};

const generateUpdateValidationSchema = () => {
    return generateValidationSchema('update');
};

export { generateCreateValidationSchema, generateUpdateValidationSchema };
