import { coreUtils } from '@trova-trip/trova-common';
import { modelTypes } from '../config/constants';
import { models } from '@trova-trip/trova-models';
import {
    NavigationCategory,
    NavigationCategoryItem,
} from '../interfaces/Navigation.types';

type categoryItem = models.tripRequest.TripRequest | models.trips.Trip;

type NavigationCategoryAttributes = {
    label: string;
    priority: number;
};

type NavigationCategoriesFactory = {
    [key: string]: NavigationCategoryAttributes;
};

export const getItineraryLength = (
    item: categoryItem,
    model: string,
): number => {
    switch (true) {
        case (item as models.trips.Trip)?.servicesByDay &&
            model === modelTypes.TRIP:
            return (item as models.trips.Trip).servicesByDay?.length || 0;
        case (item as models.tripRequest.TripRequest)?.tripLength &&
            model === modelTypes.TRIP_REQUEST:
            return (item as models.tripRequest.TripRequest).tripLength;

        default:
            return 0;
    }
};

export const getCategorySubtitle = (
    model: string,
    item: categoryItem,
): string => {
    switch (true) {
        case (item as models.trips.Trip).name && model === modelTypes.TRIP:
            return (item as models.trips.Trip).name;

        case (item as models.tripRequest.TripRequest).itinerary &&
            model === modelTypes.TRIP_REQUEST:
            return (item as models.tripRequest.TripRequest).itinerary.name;

        default:
            return '';
    }
};

export const addItemToNavigationCategory = (
    categoryItem: categoryItem,
    model: string,
    category: NavigationCategory,
    linkPath?: string,
): void => {
    const { id = '', startDate } = categoryItem;
    const { getStartEndDates } = coreUtils.dateUtils;
    const itineraryLength = getItineraryLength(categoryItem, model);
    const [formattedStartDate, formattedEndDate] = getStartEndDates(
        startDate?.toString() || '',
        itineraryLength,
        'll',
    );

    const item: NavigationCategoryItem = {
        id,
        title: getCategorySubtitle(model, categoryItem),
        subtitle: `${formattedStartDate} - ${formattedEndDate}`,
        query: { model },
    };
    if (linkPath) {
        item.link = linkPath;
    }
    category.items.push(item);
};

export const findHighestPriorityCategoryItem = (
    navigationCategories: NavigationCategory[],
): NavigationCategoryItem | undefined => {
    if (navigationCategories.length > 0) {
        const sortedCategories = navigationCategories.sort(
            (categoryA, categoryB) => categoryA.priority - categoryB.priority,
        );

        return sortedCategories[0].items[0];
    }
};

export const createNavigationCategory = (
    categoryLabel: string,
    priority: number,
): NavigationCategory => ({
    categoryLabel,
    items: [],
    priority,
});

export const createNavigationCategoriesMapFromObject = (
    categories: NavigationCategoriesFactory,
): Map<string, NavigationCategory> => {
    const sidebarCategories = new Map();
    Object.entries(categories).forEach(([categoryKey, categoryAttributes]) => {
        const { label, priority } = categoryAttributes;
        sidebarCategories.set(
            categoryKey,
            createNavigationCategory(label, priority),
        );
    });
    return sidebarCategories;
};
