import { Grid } from '@trova-trip/trova-components';
import { Route } from 'react-router-dom';
import HomeProgress from './progress/HomeProgress';
import HomeDashboard from './dashboard/HomeDashboard';
import Audience from './common/components/Audience/Audience';
import { buildHomeRoute } from './common/utils';

interface HomeContainerProps {
    showDashboard: boolean;
}

const HomeContainer = ({ showDashboard }: HomeContainerProps) => {
    const containerSize = showDashboard ? 'container.2xl' : 'container.lg';
    return (
        <>
            <Grid
                maxWidth={containerSize}
                width='full'
                marginX='auto'
                gap={{ base: 4, md: 6 }}
                marginTop={{ md: 6, lg: 4 }}
            >
                <Grid.Item columnSpan={12}>
                    <Route path={buildHomeRoute('DASHBOARD', true)}>
                        <HomeDashboard />
                    </Route>
                    <Route path={buildHomeRoute('PROGRESS', true)}>
                        <HomeProgress />
                    </Route>
                </Grid.Item>
                {!showDashboard ? (
                    <Grid.Item columnSpan={12}>
                        <Audience />
                    </Grid.Item>
                ) : null}
            </Grid>
        </>
    );
};

export default HomeContainer;
