import requestTripImage from '../../../../../../../../assets/img/host/home/progress/locked/request-trip.svg';
import BlockStep from '../common/BlockStep';
const labels = {
    title: 'Request Your Trip',
    description:
        'Use your survey data to select an itinerary and request your trip.',
};

const RequestTrip = () => {
    return <BlockStep {...labels} imagePath={requestTripImage} />;
};

export default RequestTrip;
