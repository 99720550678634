import { constants } from '@trova-trip/trova-models';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import useHomeProgressData, {
    UseHomeProgressDataReturn,
} from '../hooks/useHomeProgressData';
import useProgressStepsManager, {
    UseStepsManagerReturn,
} from '../hooks/useProgressStepsManager';
import { ProgressStepViewModel } from '../types';
import { useTrackingEvent } from '../../../../../../analytics/hooks';
import { TrackEventParameters } from '../../../../../../analytics/analytics.types';

interface OnCompleteButtonClickConfig {
    trackingEvent: TrackEventParameters;
}

export interface HomeProgressProviderValue {
    steps: UseStepsManagerReturn & {
        onCompleteButtonClick: (config?: OnCompleteButtonClickConfig) => void;
        onStepSelect: (step: ProgressStepViewModel) => void;
    };
    data: UseHomeProgressDataReturn;
}

const ProgressStepKeys = constants.host.HostHomeProgressStepKey;
type ProgressStepKeys = constants.host.HostHomeProgressStepKey;

const HomeProgressContext = createContext<HomeProgressProviderValue | null>(
    null,
);

type JSXChild = JSX.Element | JSX.Element[];

interface HomeProgressProviderProps {
    children: JSXChild;
}

const reviewStepsIds = [
    ProgressStepKeys.DESTINATIONS,
    ProgressStepKeys.TRAVEL_DATES,
    ProgressStepKeys.BUDGET,
];

const trackingEventNames = {
    [ProgressStepKeys.REQUEST_TRIP]: 'Host Navigated Forward to Request',
    [ProgressStepKeys.REVIEW_RESPONSES]: 'Host Navigated Forward to Review',
};

const HomeProgressProvider = (props: HomeProgressProviderProps) => {
    const { children } = props;
    const { trackUserEvent } = useTrackingEvent();

    const homeProgressData = useHomeProgressData();
    const {
        loadProgress,
        progressData,
        updateProgress,
        reviewData,
        loadReviews,
    } = homeProgressData;

    const progressStepManager = useProgressStepsManager(progressData.data);
    const {
        completeStep,
        currentStep,
        goToNextStep,
        setCurrentStep,
        setSteps,
        steps,
        getCurrentStep,
    } = progressStepManager;

    const isReviewStep = currentStep && reviewStepsIds.includes(currentStep.id);
    const hasReviewData = !!reviewData.data;

    useEffect(() => {
        if (progressData.data) {
            setSteps(progressData.data);
        } else {
            loadProgress();
        }
    }, [progressData.data]);

    useEffect(() => {
        if (isReviewStep && !hasReviewData) {
            loadReviews();
        }
    }, [currentStep]);

    useEffect(() => {
        if (steps && !currentStep) {
            const selectedStep = getCurrentStep();
            if (selectedStep) {
                setCurrentStep(selectedStep.id);
            }
        }
    }, [setCurrentStep]);

    const onStepSelect = (step: ProgressStepViewModel) => {
        if (step.steps && !step.locked) {
            if (step.completed === 1) {
                const lastSubstep = step.steps[step.steps.length - 1];
                setCurrentStep(lastSubstep.id);
            } else {
                const selectedSubstep = getCurrentStep(step);
                if (selectedSubstep) {
                    setCurrentStep(selectedSubstep.id);
                }
            }
        } else {
            setCurrentStep(step.id);
            if (step.locked) {
                const eventName = trackingEventNames[step.id];
                trackUserEvent({ eventName });
            }
        }
    };

    const onCompleteButtonClick = (config: OnCompleteButtonClickConfig) => {
        if (currentStep && !currentStep?.completed) {
            completeStep();
            updateProgress(currentStep);
            if (config?.trackingEvent) {
                trackUserEvent(config.trackingEvent);
            }
        } else {
            goToNextStep();
        }
    };

    const providerValue: HomeProgressProviderValue = {
        steps: { onCompleteButtonClick, onStepSelect, ...progressStepManager },
        data: homeProgressData,
    };

    return (
        <HomeProgressContext.Provider value={providerValue}>
            {children}
        </HomeProgressContext.Provider>
    );
};

const useHomeProgressContext = () => {
    const homeProgressContext = useContext(HomeProgressContext);
    if (!homeProgressContext) {
        return;
    }
    return homeProgressContext;
};

export { HomeProgressProvider, useHomeProgressContext };
