import {
    BaseBox,
    Heading,
    Text,
    Image,
    HeadingProps,
} from '@trova-trip/trova-components';

interface BlockStepProps {
    title: string;
    description: string;
    imagePath: string;
}
type BlockStepCommonProps = Omit<BlockStepProps, 'imagePath'> & {
    titleSize?: HeadingProps['fontSize'];
};

const StepCommon = (props: BlockStepCommonProps) => {
    const { title, description, titleSize } = props;
    return (
        <BaseBox>
            <BaseBox marginTop={{ base: 4, md: 6 }}>
                <Heading
                    lineHeight='normal'
                    fontWeight='bold'
                    fontSize={titleSize}
                >
                    {title}
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'base' }} marginTop={4}>
                    {description}
                </Text>
            </BaseBox>
        </BaseBox>
    );
};

const BlockStep = (props: BlockStepProps) => {
    const { imagePath, ...rest } = props;

    return (
        <BaseBox textAlign='center' padding={{ base: 3, md: 0 }}>
            <BaseBox
                height={{ base: 40, md: 64 }}
                overflow='hidden'
                borderRadius={{ base: 'none', md: 'xl' }}
            >
                <Image
                    width='fit-content'
                    height='full'
                    fit='cover'
                    src={imagePath}
                    data-gumlet='false'
                />
            </BaseBox>
            <StepCommon titleSize={{ base: 'lg', md: 'xl' }} {...rest} />
        </BaseBox>
    );
};

export default BlockStep;
