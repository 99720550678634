import { coreUtils } from '@trova-trip/trova-common';
import {
    Alert,
    BaseBox,
    Divider as DividerTC,
    Form,
    Grid,
    useReveal,
} from '@trova-trip/trova-components';
import isSameDay from 'date-fns/isSameDay';
import noop from 'lodash/noop';
import { forwardRef, useState } from 'react';
import { PricingValues } from '../ItineraryInventoryCard';
import AccommodationFields from './components/AccommodationFields';
import CostScheduleField from './components/CostScheduleField';
import SingleSupplementFields from './components/SingleSupplementFields';
import TransferFields from './components/TransferFields';
import TripFields from './components/TripFields';
import { FieldPrefix, ServiceTiming } from './constants';
import {
    scrollTo,
    transformFormValuesToCreatePayload,
    transformFormValuesToUpdatePayload,
} from './helpers';
import type {
    CostScheduleViewItem,
    CreateItineraryInventoryItemInput,
    ItineraryInventoryFormValues,
    UpdateItineraryInventoryItemInput,
} from './types';
import {
    generateCreateValidationSchema,
    generateUpdateValidationSchema,
} from './validationSchema';

const { getZeroUTCResetDateObj } = coreUtils.dateUtils;

const Divider = () => (
    <Grid.Item columnSpan={12}>
        <DividerTC color='blueGray.300' />
    </Grid.Item>
);

const DuplicatedItemAlert = ({ onClose }) => (
    <Alert
        isClosable
        variant='inline'
        onCloseAlert={onClose}
        status='warning'
        title='You already have a departure available on this date'
        description='You can still save this new departure on the same date.'
        marginBottom={6}
    />
);

const ErrorAlert = ({ description }) => (
    <Grid.Item columnSpan={12}>
        <Alert
            variant='inline'
            status='error'
            onCloseAlert={noop}
            title='Error'
            description={description}
        />
    </Grid.Item>
);

const InUseAlert = () => {
    const { isOpen, triggerClose } = useReveal({ defaultIsOpen: true });

    if (!isOpen) {
        return null;
    }

    return (
        <Alert
            isClosable
            variant='inline'
            onCloseAlert={triggerClose}
            status='warning'
            title='This date is in use'
            description='Trip Quantity can only be increased.'
            marginBottom={6}
        />
    );
};

type SubmitArgs =
    | { action: 'create'; payload: CreateItineraryInventoryItemInput }
    | { action: 'update'; payload: UpdateItineraryInventoryItemInput };

type InventoryItemFormSubmitFn = (args: SubmitArgs) => void | Promise<void>;

interface ItineraryInventoryFormProps {
    initialValues: ItineraryInventoryFormValues;
    onSubmit: InventoryItemFormSubmitFn;
    formRef: React.RefObject<HTMLFormElement>;
    pricingValues: PricingValues;
    existingStartDates: Date[];
    errorMessage: string | undefined;
    isInventoryItemInUse?: boolean;
}

const createSchema = generateCreateValidationSchema();
const updateSchema = generateUpdateValidationSchema();

const ItineraryInventoryForm = forwardRef<
    HTMLDivElement,
    ItineraryInventoryFormProps
>((props, ref) => {
    const {
        initialValues,
        onSubmit,
        formRef,
        pricingValues,
        existingStartDates,
        errorMessage,
        isInventoryItemInUse,
    } = props;

    const isUpdate = !!initialValues.inventoryItem.id;
    const schema = isUpdate ? updateSchema : createSchema;
    const [isDuplicatedStartDate, setIsDuplicatedStartDate] = useState(false);

    const [values, setValues] = useState(initialValues);

    const onAddOrRemoveCostScheduleItem = (
        items: CostScheduleViewItem[],
        values: ItineraryInventoryFormValues,
    ) => {
        setValues({
            [FieldPrefix]: { ...values[FieldPrefix], costSchedule: items },
        });
    };

    const onAddCostScheduleItem = (
        items: CostScheduleViewItem[],
        values: ItineraryInventoryFormValues,
    ) => {
        scrollTo(formContainerRef, 'bottom');
        onAddOrRemoveCostScheduleItem(items, values);
    };

    const onRemoveCostScheduleItem = (
        items: CostScheduleViewItem[],
        values: ItineraryInventoryFormValues,
    ) => onAddOrRemoveCostScheduleItem(items, values);

    const handleFormOnChange = (values: ItineraryInventoryFormValues) => {
        const { startDate } = values.inventoryItem;
        if (startDate) {
            const duplicatedDate = existingStartDates.find((date) => {
                return isSameDay(
                    new Date(date),
                    getZeroUTCResetDateObj(new Date(startDate)),
                );
            });
            setIsDuplicatedStartDate(!!duplicatedDate);
        }
    };

    const handleFormSubmit = (values: ItineraryInventoryFormValues) => {
        if (isUpdate) {
            const payload = transformFormValuesToUpdatePayload(values);
            return onSubmit({ action: 'update', payload });
        }
        const payload = transformFormValuesToCreatePayload(values);
        onSubmit({ action: 'create', payload });
    };

    const { currencyCode } = pricingValues;

    const formContainerRef = ref as React.RefObject<HTMLDivElement>;

    return (
        <BaseBox ref={formContainerRef}>
            {isDuplicatedStartDate ? (
                <DuplicatedItemAlert
                    onClose={() => setIsDuplicatedStartDate(false)}
                />
            ) : null}

            {isInventoryItemInUse ? <InUseAlert /> : null}

            <Form
                autoTrim
                name='itinerary-inventory-form'
                initialValues={values}
                onSubmit={handleFormSubmit}
                formRef={formRef}
                validationSchema={schema}
                onError={() => scrollTo(formContainerRef, 'error')}
                onChange={handleFormOnChange}
                config={{ onSubmit: { stopPropagation: true } }}
            >
                {({ values }) => (
                    <Grid>
                        <TripFields
                            isStartDateDisabled={isUpdate}
                            isDisabled={isInventoryItemInUse}
                        />
                        <Divider />
                        <SingleSupplementFields
                            currencyCode={currencyCode}
                            isDisabled={isInventoryItemInUse}
                        />
                        <TransferFields
                            currencyCode={currencyCode}
                            isDisabled={isInventoryItemInUse}
                        />
                        <AccommodationFields
                            timing={ServiceTiming.PRE_TRIP}
                            currencyCode={currencyCode}
                            isDisabled={isInventoryItemInUse}
                        />
                        <AccommodationFields
                            timing={ServiceTiming.POST_TRIP}
                            currencyCode={currencyCode}
                            isDisabled={isInventoryItemInUse}
                        />
                        <Divider />
                        <CostScheduleField
                            initialItems={values[FieldPrefix].costSchedule}
                            currencyCode={currencyCode}
                            onAddItem={(items) => {
                                onAddCostScheduleItem(items, values);
                            }}
                            onRemoveItem={(items) => {
                                onRemoveCostScheduleItem(items, values);
                            }}
                            isDisabled={isInventoryItemInUse}
                        />
                        {errorMessage ? (
                            <ErrorAlert description={errorMessage} />
                        ) : null}
                    </Grid>
                )}
            </Form>
        </BaseBox>
    );
});

ItineraryInventoryForm.displayName = 'ItineraryInventoryForm';

export default ItineraryInventoryForm;
export type { InventoryItemFormSubmitFn, ItineraryInventoryFormProps };
