import {
    BaseBox,
    Image,
    Text,
    Heading,
    Stack,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import imgPlaceholder from '../../../../../../../../assets/img/placeholder.jpg';
import { useHomeProgressContext } from '../../../provider/HomeProgressProvider';
import WidgetLoadingFallback from '../../../../dashboard/components/WidgetLoadingFallback/WidgetLoadingFallback';
import ReviewBaseStep from './ReviewBaseStep';
import { TrackEventParameters } from '../../../../../../../../analytics/analytics.types';
import { ProgressStepKeys } from '../common/progressStepLayoutConfig';

type Destination = models.hostHomeTab.DestinationResponse;

interface DestinationProps {
    destination: Destination;
}

interface TripDestinationsContentProps {
    destinations: Destination[] | undefined;
}

const labels = {
    title: 'Top Destinations',
    description:
        'Your audience insights are rolling in! So far, your top destinations are $destinations.',
    descriptionPlaceholder:
        'Your audience insights are rolling in! Your top destinations are being computed.',
};

const getDestinationsDescriptions = (
    destinations: Destination[] | undefined,
) => {
    if (!destinations) {
        return labels.descriptionPlaceholder;
    }

    const destinationsNames = destinations.map(
        (destination) => destination.name,
    );

    const str = `${destinationsNames
        .slice(0, -1)
        .join(', ')} and ${destinationsNames.at(-1)}`;

    return labels.description.replace(`$destinations`, str);
};

const Destination = ({ destination }: DestinationProps) => (
    <Stack
        direction='column'
        spacing={{ base: 2, md: 3 }}
        align='stretch'
        flexGrow={1}
        width='full'
    >
        <BaseBox
            borderRadius='xl'
            height={{ base: '24', md: '32' }}
            overflow='hidden'
        >
            <Image
                src={destination.photo || imgPlaceholder}
                width='full'
                height='full'
                fit='cover'
            />
        </BaseBox>
        <Heading
            fontSize={{ base: 'sm', md: 'xl' }}
            fontWeight='bold'
            lineHeight='none'
        >
            {destination.name}
        </Heading>
        <Text
            fontSize={{ base: 'xs', md: 'base' }}
            lineHeight='none'
            fontWeight='medium'
            fontStyle='italic'
            color='blueGray.500'
        >
            {destination.responses} responses
        </Text>
    </Stack>
);

const TripDestinationsContent = ({
    destinations,
}: TripDestinationsContentProps) => {
    if (!destinations) {
        return (
            <WidgetLoadingFallback
                skeletonLoaderHeight='36'
                skeletonLoaderWidth='full'
            />
        );
    }
    return (
        <Stack
            spacing={{ base: 3, md: 7 }}
            justify='stretch'
            align='stretch'
            wrap='nowrap'
        >
            {destinations.map((destination) => {
                return (
                    <Destination
                        key={destination.name}
                        destination={destination}
                    />
                );
            })}
        </Stack>
    );
};

const TripDestinations = () => {
    const progressContext = useHomeProgressContext();

    if (!progressContext) {
        return null;
    }

    const {
        data: {
            reviewData: { data },
        },
    } = progressContext;

    const trackingEvent: TrackEventParameters = {
        eventName: 'Host Reviewed Destinations',
        properties: { destinations: data?.destinations },
    };

    const description = getDestinationsDescriptions(data?.destinations);

    return (
        <ReviewBaseStep
            stepKey={ProgressStepKeys.DESTINATIONS}
            labels={{
                title: labels.title,
                description,
            }}
            customContent={
                <TripDestinationsContent destinations={data?.destinations} />
            }
            trackingEvent={trackingEvent}
        />
    );
};

export default TripDestinations;
