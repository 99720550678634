import { models } from '@trova-trip/trova-models';
import { get, post, put, deleteRequest } from '../state/utils/api';
import { ApiResponse, isApiResponse, isErrorApiResponse } from './types';

type ItineraryInventoryItem =
    models.itineraryInventoryItems.ItineraryInventoryItem;

type SavedItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;

type CreateItineraryInventoryItemPayload = {
    itineraryId: string;
    data: models.itineraryInventoryItems.CreateItineraryInventoryItemInput;
};

type UpdateItineraryInventoryItemPayload = {
    itineraryId: string;
    inventoryItemId: string;
    data: models.itineraryInventoryItems.UpdateItineraryInventoryItemInput;
};

type DeleteItineraryInventoryItemPayload = {
    itineraryId: string;
    inventoryItemId: string;
};

type AsyncApiResponse<T> = Promise<ApiResponse<T>>;

const ITINERARIES_PATH = '/api/me/itineraries';

const normalizeResponse = <T>(data: ApiResponse<T> | T): ApiResponse<T> => {
    if (data && isApiResponse<T>(data)) {
        return isErrorApiResponse(data) ? { ...data } : data;
    }
    return data
        ? { success: true, data }
        : {
              success: false,
              error: 'There was an error processing your request',
          };
};

const fetchInventoryItems = async (
    itineraryId: string,
): AsyncApiResponse<ItineraryInventoryItem[]> => {
    const url = `${ITINERARIES_PATH}/${itineraryId}/inventory`;
    const items = await get(url);
    return normalizeResponse(items);
};

const fetchInventoryItem = async (
    itineraryId: string,
    itineraryItemId: string,
): AsyncApiResponse<SavedItineraryInventoryItem> => {
    const url = `${ITINERARIES_PATH}/${itineraryId}/inventory/${itineraryItemId}`;
    const items = await get(url);
    return normalizeResponse(items);
};

const createInventoryItem = async (
    payload: CreateItineraryInventoryItemPayload,
): AsyncApiResponse<ItineraryInventoryItem> => {
    const { itineraryId, data } = payload;

    try {
        const item = await post(
            `${ITINERARIES_PATH}/${itineraryId}/inventory`,
            data,
        );
        return normalizeResponse(item);
    } catch (error) {
        return normalizeResponse(error.response?.data);
    }
};

const updateInventoryItem = async (
    payload: UpdateItineraryInventoryItemPayload,
): AsyncApiResponse<ItineraryInventoryItem> => {
    const { itineraryId, inventoryItemId, data } = payload;

    try {
        const item = await put(
            `${ITINERARIES_PATH}/${itineraryId}/inventory/${inventoryItemId}`,
            undefined,
            data,
        );
        return normalizeResponse(item);
    } catch (error) {
        return normalizeResponse(error.response?.data);
    }
};

const deleteInventoryItem = async (
    payload: DeleteItineraryInventoryItemPayload,
): AsyncApiResponse<ItineraryInventoryItem> => {
    const { itineraryId, inventoryItemId } = payload;
    const item = await deleteRequest(
        `${ITINERARIES_PATH}/${itineraryId}/inventory/${inventoryItemId}`,
    );
    return normalizeResponse(item);
};

export type {
    CreateItineraryInventoryItemPayload,
    UpdateItineraryInventoryItemPayload,
    DeleteItineraryInventoryItemPayload,
};

export {
    createInventoryItem,
    deleteInventoryItem,
    fetchInventoryItem,
    fetchInventoryItems,
    updateInventoryItem,
};
