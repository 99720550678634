import { Grid } from '@trova-trip/trova-components';
import {
    HostAudienceData,
    WidgetCallbacks,
    WidgetNames,
} from '../../../common/types';
import { buildHostProductRoute } from '../../../common/utils';
import AudienceContent from './AudienceContent';
import { useEffect } from 'react';
import useHomeWidgetData from '../../../dashboard/hooks/useHomeWidgetData';
import WidgetLoadingFallback from '../../../dashboard/components/WidgetLoadingFallback/WidgetLoadingFallback';
import WidgetHeading from '../../../dashboard/components/common/WidgetHeading';
import WidgetCard from '../../../dashboard/components/common/WidgetCard';

const labels = {
    title: 'Audience',
};

interface AudienceProps extends WidgetCallbacks {}

const Audience = (props: AudienceProps) => {
    const { onError, onLoad } = props;

    const { data: hostAudience, load } = useHomeWidgetData<HostAudienceData>(
        WidgetNames.AUDIENCE,
    );

    useEffect(() => {
        load().then((res) => {
            if (res.success) {
                onLoad?.();
            } else {
                onError?.();
            }
        });
    }, []);

    if (!hostAudience) {
        return <WidgetLoadingFallback skeletonLoaderHeight='64' />;
    }

    const audienceRoute = buildHostProductRoute('AUDIENCE');

    return (
        <WidgetCard>
            <WidgetHeading>{labels.title}</WidgetHeading>
            <Grid
                marginTop={{ base: 0, md: 3 }}
                gap={6}
                alignItems='stretch'
                justifyItems='stretch'
            >
                <AudienceContent
                    hostAudience={hostAudience}
                    audienceRoute={audienceRoute}
                />
            </Grid>
        </WidgetCard>
    );
};

export default Audience;
