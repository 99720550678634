import { ErrorMessage, ErrorMessageProps, Skeleton, Stack } from '@trova-trip/trova-components';
import useIsLargeScreen from '../../../../../../common/hooks/useIsLargeScreen';
import WidgetLoadingFallback from '../../../dashboard/components/WidgetLoadingFallback/WidgetLoadingFallback';

interface SkeletonProps {
    showError: boolean;
}

const errorLabels: ErrorMessageProps = {
    title: 'Oops!',
    description: 'We have some troubles loading your data',
};

const ProgressSkeleton = () => {
    const isLargeScreen = useIsLargeScreen({ includeTabletResolution: true });
    return (
        <WidgetLoadingFallback
            skeletonLoaderHeight={{ base: 20, md: '14' }}
            renderCount={isLargeScreen ? 3 : 1}
            skeletonLoaderWidth='full'
        />
    );
};

const StepSkeleton = ({ showError }: SkeletonProps) => (
    <Skeleton
        fallbackElement={<ErrorMessage {...errorLabels} />}
        showFallback={showError}
        width='full'
    >
        <Stack direction='column' spacing={4} padding={{ base: 4, md: 0 }} width='full'>
            <Skeleton height={48} width='full' />
            <Skeleton height={{ base: 6, md: 12 }} width='30%' />
            <Skeleton height={{ base: 4, md: 6 }} width='100%' />
            <Skeleton height={{ base: 4, md: 6 }} width='70%' />
            <Skeleton height={{ base: 6, md: 12 }} width='20%' />
        </Stack>
    </Skeleton>
);

export { ProgressSkeleton, StepSkeleton };
