import {
    HeadingProps,
    Heading,
    BaseBox,
    Text,
    Image,
} from '@trova-trip/trova-components';
import { useHistory } from 'react-router-dom';
import { buildHostProductRoute } from '../../../../common/utils';
import { useTrackingEvent } from '../../../../../../../../analytics/hooks';
import CTAWrapper from '../common/CTAWrapper';
import ProgressStep from '../common/ProgressStep';
import { ProgressStepKeys } from '../common/progressStepLayoutConfig';
import requestTripImage from '../../../../../../../../assets/img/host/home/progress/request-trip.png';
import requestTripImageMobile from '../../../../../../../../assets/img/host/home/progress/request-trip-mobile.png';

const copy = {
    title: 'Request a Trip',
    description:
        'We recommend 100 survey responses before you formally request a trip! Build your group trip around the date, location, and budget responses.',
};

const titleProps: HeadingProps = {
    marginTop: { base: 5, md: 6 },
    fontSize: { base: 'xl', md: '4xl' },
    paddingX: 3,
    children: copy.title,
};

const BrowseItineraries = (): JSX.Element => {
    const history = useHistory();
    const { trackUserEvent } = useTrackingEvent();
    const itinerariesRoute = buildHostProductRoute('ITINERARIES');
    const onClick = () => {
        trackUserEvent({ eventName: 'Host Browsed Itineraries' });
        history.push(itinerariesRoute);
    };

    return (
        <ProgressStep
            stepKey={ProgressStepKeys.REQUEST_TRIP}
            containerProps={{ paddingX: 3 }}
            content={
                <BaseBox
                    height={40}
                    overflow='hidden'
                    borderRadius={{ md: 'xl' }}
                >
                    <Image
                        width='full'
                        height='full'
                        fit='cover'
                        src={{
                            base: requestTripImageMobile,
                            md: requestTripImage,
                        }}
                    />
                </BaseBox>
            }
            title={<Heading {...titleProps} />}
            descriptionProps={{
                description: (
                    <Text fontSize={{ base: 'sm', md: 'base' }} marginTop={4}>
                        {copy.description}
                    </Text>
                ),
            }}
            footer={
                <CTAWrapper
                    {...{
                        containerProps: { paddingX: 3 },
                        primaryCTAProps: {
                            size: 'md',
                            onClick,
                            label: 'Browse Trips',
                        },
                    }}
                />
            }
        />
    );
};

export default BrowseItineraries;
