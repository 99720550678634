import { Heading, HeadingProps, Text } from '@trova-trip/trova-components';
import { TrackEventParameters } from '../../../../../../../../analytics/analytics.types';
import { useHomeProgressContext } from '../../../provider/HomeProgressProvider';
import CTAWrapper from '../common/CTAWrapper';
import ProgressStep from '../common/ProgressStep';
import { ProgressStepKey } from '../common/progressStepLayoutConfig';

interface ReviewBaseStepProps {
    labels: { title: string; description: string };
    customContent: JSX.Element;
    trackingEvent: TrackEventParameters;
    stepKey: ProgressStepKey;
}

const titleProps: Omit<HeadingProps, 'children'> = {
    marginTop: { base: 0, md: 3 },
    marginBottom: { base: 6, md: 0 },
    fontSize: { base: 'lg', md: '2xl' },
};

const ReviewBaseStep = (props: ReviewBaseStepProps) => {
    const progressContext = useHomeProgressContext();
    if (!progressContext) {
        return null;
    }

    const { labels, customContent, trackingEvent, stepKey } = props;
    const {
        steps: { onCompleteButtonClick },
    } = progressContext;

    return (
        <ProgressStep
            stepKey={stepKey}
            containerProps={{
                paddingX: { base: 3, md: 0 },
                paddingTop: { base: 3, md: 0 },
            }}
            title={
                <Heading lineHeight='normal' {...titleProps}>
                    {labels.title}
                </Heading>
            }
            content={customContent}
            descriptionProps={{
                description: (
                    <Text fontSize={{ base: 'sm', md: 'base' }} marginTop={4}>
                        {labels?.description}
                    </Text>
                ),
            }}
            footer={
                <CTAWrapper
                    {...{
                        primaryCTAProps: {
                            label: 'Next',
                            onClick: () =>
                                onCompleteButtonClick({ trackingEvent }),
                        },
                    }}
                />
            }
        />
    );
};

export default ReviewBaseStep;
