import { constants } from '@trova-trip/trova-models';

const { HostHomeProgressStepKey } = constants.host;

export const ProgressStepKeys: { [key in string]: string } = {
    ...HostHomeProgressStepKey,
    REQUEST_TRIP_RECOMMENDATIONS: 'request_trip_recommendations',
};

type ProgressStepKey = typeof ProgressStepKeys.BUDGET;

enum TEMPLATE_AREAS {
    title = 'title',
    description = 'description',
    content = 'content',
    footer = 'footer',
}

const STEPS_LAYOUT_TEMPLATES = {
    default: {
        base: `"${TEMPLATE_AREAS.content}"
               "${TEMPLATE_AREAS.title}"
               "${TEMPLATE_AREAS.description}"
               "${TEMPLATE_AREAS.footer}"`,
    },
    reviewStep: {
        base: `"${TEMPLATE_AREAS.title}"
               "${TEMPLATE_AREAS.content}"   
               "${TEMPLATE_AREAS.description}"
               "${TEMPLATE_AREAS.footer}"`,
        md: `"${TEMPLATE_AREAS.content}"
             "${TEMPLATE_AREAS.title}"
             "${TEMPLATE_AREAS.description}"
             "${TEMPLATE_AREAS.footer}"`,
    },
    requestTripWithRecommendations: {
        base: `"${TEMPLATE_AREAS.title}"
               "${TEMPLATE_AREAS.description}"
               "${TEMPLATE_AREAS.content}"`,
    },
};

const STEP_MAPPER = {
    [ProgressStepKeys.START_SURVEYING]: STEPS_LAYOUT_TEMPLATES.default,
    [ProgressStepKeys.FIFTY_QUALIFIED_RESPONSES]:
        STEPS_LAYOUT_TEMPLATES.default,
    [ProgressStepKeys.DESTINATIONS]: STEPS_LAYOUT_TEMPLATES.reviewStep,
    [ProgressStepKeys.TRAVEL_DATES]: STEPS_LAYOUT_TEMPLATES.reviewStep,
    [ProgressStepKeys.BUDGET]: STEPS_LAYOUT_TEMPLATES.reviewStep,
    [ProgressStepKeys.REQUEST_TRIP]: STEPS_LAYOUT_TEMPLATES.default,
    [ProgressStepKeys.REQUEST_TRIP_RECOMMENDATIONS]:
        STEPS_LAYOUT_TEMPLATES.requestTripWithRecommendations,
};

export { STEP_MAPPER, TEMPLATE_AREAS };
export type { ProgressStepKey };
