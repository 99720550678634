import { Grid, Heading, Hide, Stack } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import WidgetCard from '../../dashboard/components/common/WidgetCard';
import { useHomeProgressContext } from '../provider/HomeProgressProvider';
import { StepSkeleton } from './skeletons/Skeletons';
import ProgressTree from './ProgressTree';
import { ProgressStepViewModel } from '../types';

interface StepViewProps {
    isLoading: boolean;
    error: boolean;
    currentStep: ProgressStepViewModel | undefined;
}

const StepView = ({
    currentStep,
    isLoading,
    error,
}: StepViewProps): JSX.Element => {
    const CurrentStepView = currentStep?.ViewComponent ?? (() => null);
    const showSkeleton = isLoading || error;
    return (
        <Card
            padding={0}
            borderRadius={{ base: '2xl', md: 'none' }}
            overflow='hidden'
            paddingBottom={4}
            backgroundColor={{
                base: 'neutral.white',
                md: 'transparent',
            }}
            width='full'
            height='full'
        >
            {showSkeleton ? (
                <Stack
                    height='full'
                    width='full'
                    justify='center'
                    align='center'
                >
                    <StepSkeleton showError={error} />
                </Stack>
            ) : (
                <CurrentStepView isLocked={currentStep?.locked} />
            )}
        </Card>
    );
};

const ProgressWidget = (): JSX.Element | null => {
    const progressContext = useHomeProgressContext();
    if (!progressContext) {
        return null;
    }

    const {
        steps: { currentStep },
        data: { progressData },
    } = progressContext;

    const { isLoading, error } = progressData;

    return (
        <WidgetCard
            backgroundColor={{
                base: 'transparent',
                md: 'neutral.white',
            }}
            minHeight={{ base: '48', md: 'auto' }}
        >
            <Grid gap={4} height='full'>
                {!error ? (
                    <Grid.Item
                        columnSpan={{ base: 12, md: 4 }}
                        paddingLeft={{ md: 4 }}
                    >
                        <Hide breakpoint={{ max: 'md' }}>
                            <Heading
                                as='h2'
                                lineHeight='normal'
                                fontSize='2xl'
                                fontWeight='medium'
                            >
                                3 Steps to Success
                            </Heading>
                        </Hide>
                        <ProgressTree />
                    </Grid.Item>
                ) : null}
                <Grid.Item
                    columnSpan={{
                        base: 12,
                        md: error ? 12 : 8,
                    }}
                    height='full'
                    marginTop={{ md: error ? 8 : 'auto' }}
                >
                    <StepView
                        currentStep={currentStep}
                        isLoading={isLoading}
                        error={error}
                    />
                </Grid.Item>
            </Grid>
        </WidgetCard>
    );
};

export default ProgressWidget;
