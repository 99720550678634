import { Stack, StackProps } from '@trova-trip/trova-components';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import { ReactElement } from 'react';

export interface CTAWrapperProps {
    primaryCTAProps?: Omit<ButtonProps, 'children'> & { label: string };
    secondaryCTAProps?: Omit<ButtonProps, 'children'> & { label: string };
    primaryCustomCTA?: ReactElement;
    containerProps?: StackProps;
}
const CTAWrapper = ({
    primaryCTAProps,
    primaryCustomCTA,
    secondaryCTAProps,
    containerProps,
}: CTAWrapperProps): JSX.Element | null => {
    const primaryCTA = primaryCTAProps ? (
        <Button
            size='md'
            isFullWidth={{ base: true, md: false }}
            {...primaryCTAProps}
        >
            {primaryCTAProps.label}
        </Button>
    ) : null;

    return primaryCTAProps || primaryCustomCTA || secondaryCTAProps ? (
        <Stack
            spacing={{ base: 4, md: 5 }}
            marginTop={{ base: 4, md: 8 }}
            wrap='nowrap'
            {...containerProps}
        >
            {primaryCustomCTA ? primaryCustomCTA : primaryCTA}
            {secondaryCTAProps ? (
                <Button
                    size='md'
                    variant='secondary'
                    isFullWidth={{ base: true, md: false }}
                    {...secondaryCTAProps}
                >
                    {secondaryCTAProps.label}
                </Button>
            ) : null}
        </Stack>
    ) : null;
};
export default CTAWrapper;
