import { models } from '@trova-trip/trova-models';
import { useState, useEffect } from 'react';
import { SwitchProps } from '@trova-trip/trova-components';
import { useGetSurvey } from './useGetSurvey';
import { emptySurveyData } from '../Audience.constants';
import { UserStatuses } from '../../../../../config/constants';
import { AudienceSummaryProps } from '../components/AudienceSummary';
import { formatCurrency } from '../../../../../applications/utils';
import { usePublicProfileUrl } from '../../../hooks/usePublicProfileUrl';
import { useSelector } from '../../../../../state/hooks';

type BaseUser = models.users.BaseUser;

interface useAudienceReturnProps {
    audienceSummaryData: AudienceSummaryProps;
    isMetricPopulated: boolean;
    toggleQualifiedReport: (event) => void;
    isQualifiedResponse: boolean;
    audienceResponses: models.survey.AudienceSurveySubmissionsData;
}

const hostAvailableAudience = new Set([
    UserStatuses.PLANNING,
    UserStatuses.LAUNCHED,
    UserStatuses.CONFIRMED,
    UserStatuses.RENEWED,
]);

const getLinkLockedConditions = (
    user?: BaseUser,
): {
    isEmailLinkLocked: boolean;
    isSurveyLinkLocked: boolean;
} => {
    const defaultConditions = {
        isEmailLinkLocked: true,
        isSurveyLinkLocked: true,
    };

    if (!user) {
        return defaultConditions;
    }

    const { status, active } = user;

    return {
        isEmailLinkLocked: status ? !hostAvailableAudience.has(status) : true,
        isSurveyLinkLocked: !active,
    };
};

const useAudience = (isHostQualified: boolean): useAudienceReturnProps => {
    const surveyData = useGetSurvey();
    const [isQualifiedResponse, setIsQualifiedResponse] =
        useState<boolean>(isHostQualified);

    const profile = useSelector(({ profile }) => profile.current);
    const { isEmailLinkLocked, isSurveyLinkLocked } =
        getLinkLockedConditions(profile);

    const hostProfileLink = usePublicProfileUrl();

    const [audienceResponses, setAudienceResponses] =
        useState<models.survey.AudienceSurveySubmissionsData>(emptySurveyData);

    const {
        allResponses = emptySurveyData,
        qualifiedResponses = emptySurveyData,
        marketingListSize: totalAudience = 0,
    } = surveyData || {};

    useEffect(() => {
        setAudienceResponses(getAudienceResponses(isHostQualified));

        setIsQualifiedResponse(isHostQualified);
    }, [isHostQualified, surveyData]);

    const toggleQualifiedReport: SwitchProps['onChange'] = (event) => {
        const eventValue = event.metadata.value;
        setAudienceResponses(getAudienceResponses(eventValue));

        setIsQualifiedResponse(eventValue);
    };

    const getAudienceResponses = (isHostQualified: boolean) => {
        const audienceSurveys = isHostQualified
            ? qualifiedResponses
            : allResponses;
        return audienceSurveys;
    };

    const { responses, budgetMedian } = audienceResponses.metrics;

    const totalSurveyResponses = responses.data || 0;
    const medianBudget = budgetMedian.data
        ? formatCurrency(budgetMedian.data)
        : 'N/A';

    const responsesSummary = {
        medianBudget,
        qualifiedResponses: audienceResponses.metrics.qualifiedResponses.data,
    };

    const responsesCards = {
        emailCollections: {
            total: Math.abs(totalAudience - totalSurveyResponses),
            link: hostProfileLink,
            isLocked: isEmailLinkLocked,
        },
        surveyCollections: {
            total: totalSurveyResponses,
            link: hostProfileLink,
            isLocked: isSurveyLinkLocked,
        },
    };

    const audienceSummaryData = {
        ...responsesCards,
        totalAudience,
        responsesSummary,
    };

    const isMetricPopulated = allResponses.metrics?.responses?.data >= 5;

    return {
        audienceSummaryData,
        isMetricPopulated,
        toggleQualifiedReport,
        isQualifiedResponse,
        audienceResponses,
    };
};

export default useAudience;
