import LockedReviewResponses from '../locked/ReviewResponses';
import { StepViewProps } from '../../../types';

const ReviewResponses = ({ isLocked }: StepViewProps) => {
    if (isLocked) {
        return <LockedReviewResponses />;
    }
    return null;
};

export default ReviewResponses;
