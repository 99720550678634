export const redirectToLoginWithDeepLinkParam = (): void => {
    const destination: string = encodeURIComponent(
        `${window.location.pathname}${window.location.search}`,
    );
    return window.location.replace(
        `${window.location.origin}/login?destination=${destination}`,
    );
};

export const getDeepLinkDestination = (): string | null => {
    const queryString: string = window.location.search;
    return new URLSearchParams(queryString).get('destination');
};

export const getActionUrl = (): { type: string; url: string } | undefined => {
    const queryString: string = window.location.search;
    const type = new URLSearchParams(queryString).get('action');
    const url = new URLSearchParams(queryString).get('url');

    if (type && url) {
        return { type, url };
    }
};
