import { useState } from 'react';
import { Grid, Stack, Video, useReveal } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import WidgetCard from '../../../dashboard/components/common/WidgetCard';
import WidgetHeading from '../../../dashboard/components/common/WidgetHeading';
import whenToPost from '../../../../../../../assets/img/host/home/progress/survey-resources/when-to-post.svg';
import examplesTopHost from '../../../../../../../assets/img/host/home/progress/survey-resources/examples-top-hosts.png';
import useIsLargeScreen from '../../../../../../common/hooks/useIsLargeScreen';
import { useTrackingEvent } from '../../../../../../../analytics/hooks';
import { useSelector } from '../../../../../../../state/hooks';
import DocumentationCard from './DocumentationCard';
import SurveyTimelineModal from './SurveyTimelineModal';

const SURVEY_ARTICLES_URL =
    'https://trovatrip.com/host/host-resources/tag/2-survey-your-community';

const EXAMPLES_FROM_TOP_HOSTS_VIDEO_URL = 'https://vimeo.com/930356418';

const VIEWPORT_MARGIN = 0.9;
const VIDEO_DIMENSIONS = { width: 630, height: 1366 };
const VIDEO_ASPECT_RATIO = VIDEO_DIMENSIONS.width / VIDEO_DIMENSIONS.height;
const VIDEO_MOBILE_WIDTH = '80vw';

const SurveyResources = () => {
    const [isSurveyTimelineOpen, setIsSurveyTimelineOpen] =
        useState<boolean>(false);
    const isMobile = !useIsLargeScreen({ includeTabletResolution: true });
    const { current: currentUser } = useSelector((state) => state.profile);
    const { trackUserEvent } = useTrackingEvent();
    const {
        isOpen: isVideoExamplesOpen,
        triggerClose: triggerVideoExamplesClose,
        triggerOpen: triggerVideoExamplesOpen,
    } = useReveal();

    const { id: userId, status: userStatus } = currentUser || {};

    const videoWidth =
        window.innerHeight * VIDEO_ASPECT_RATIO * VIEWPORT_MARGIN + 'px';

    const openVideoExamples = (): void => {
        trackUserEvent({
            eventName: 'Host viewed survey examples video',
            properties: {
                userId,
                userStatus,
            },
        });
        triggerVideoExamplesOpen();
    };

    const openSurveyArticles = () => {
        trackUserEvent({
            eventName: 'Host viewed Survey Articles',
            properties: {
                userId,
                userStatus,
            },
        });
        window.open(SURVEY_ARTICLES_URL, '_blank');
    };

    const openSurveyPromotionTimeline = () => {
        trackUserEvent({
            eventName: 'Host viewed static survey promotion timeline',
            properties: {
                userId,
                userStatus,
            },
        });
        setIsSurveyTimelineOpen(true);
    };

    let videoDeviceTypeProps: {} = {
        width: videoWidth,
        showFullscreenButton: false,
        autoplay: true,
        muted: true,
    };

    if (isMobile) {
        videoDeviceTypeProps = {
            width: VIDEO_MOBILE_WIDTH,
            fullscreenMode: true,
            showFullscreenButton: true,
            eventHandlers: { onExitFullscreen: triggerVideoExamplesClose },
        };
    }

    const exampleVideoCopy = `See how these Hosts used video to effectively ${
        isMobile
            ? 'survey!'
            : 'promote their survey and excite their communities!'
    }`;

    return (
        <>
            <WidgetCard>
                <Grid
                    gap={{ base: 2, md: 4 }}
                    alignItems='stretch'
                    justifyItems='stretch'
                >
                    <Grid.Item columnSpan={12}>
                        <Stack
                            width='full'
                            align='center'
                            justify='space-between'
                        >
                            <WidgetHeading
                                color='blueGray.800'
                                as='h4'
                                marginBottom={0}
                            >
                                Survey Resources
                            </WidgetHeading>
                            <Button
                                variant='tertiary'
                                rightIcon={isMobile ? 'arrow-right' : 'offsite'}
                                size={{ base: 'sm', md: 'lg' }}
                                onClick={openSurveyArticles}
                            >
                                Survey Articles
                            </Button>
                        </Stack>
                    </Grid.Item>
                    <Grid.Item columnSpan={{ base: 12, md: 6 }}>
                        <DocumentationCard
                            heading='Examples from Top Hosts'
                            copy={exampleVideoCopy}
                            onClick={openVideoExamples}
                            imageSrc={examplesTopHost}
                        />
                    </Grid.Item>
                    <Grid.Item columnSpan={{ base: 12, md: 6 }}>
                        <DocumentationCard
                            onClick={openSurveyPromotionTimeline}
                            heading='What to Post and When'
                            copy="Here's a posting cadence that makes promotion easy."
                            imageSrc={whenToPost}
                        />
                    </Grid.Item>
                </Grid>
            </WidgetCard>
            {isVideoExamplesOpen ? (
                <Video
                    src={EXAMPLES_FROM_TOP_HOSTS_VIDEO_URL}
                    variant='modal'
                    borderRadius={{ md: 'xl' }}
                    isOpen={isVideoExamplesOpen}
                    onClose={triggerVideoExamplesClose}
                    {...videoDeviceTypeProps}
                />
            ) : null}
            {isSurveyTimelineOpen ? (
                <SurveyTimelineModal
                    isOpen={isSurveyTimelineOpen}
                    isMobile={isMobile}
                    onCloseClick={() => setIsSurveyTimelineOpen(false)}
                />
            ) : null}
        </>
    );
};

export default SurveyResources;
