import {
    BaseBox,
    VerticalBarChart,
    VerticalBarChartProps,
} from '@trova-trip/trova-components';
import { ReviewResponses } from '@trova-trip/trova-models/dist/models/hostHomeTab';
import { useHomeProgressContext } from '../../../provider/HomeProgressProvider';
import WidgetLoadingFallback from '../../../../dashboard/components/WidgetLoadingFallback/WidgetLoadingFallback';
import ReviewBaseStep from './ReviewBaseStep';
import { TrackEventParameters } from '../../../../../../../../analytics/analytics.types';
import { ProgressStepKeys } from '../common/progressStepLayoutConfig';

const labels = {
    title: 'Travel Dates',
    description:
        'Request a trip during your audience’s prefered travel dates to ensure they’re available. Your top destination and dates may not align, so be sure to find an itinerary that meets your Travelers’ preferences.',
};

const findMaxChartValue = (
    datesChartData: VerticalBarChartProps['chartData'],
): number => Math.max(...datesChartData.map(({ value }) => value), 0);

interface TripDatesContentProps {
    surveyDates: ReviewResponses['travelDates'] | undefined;
}

const TripDatesContent = ({ surveyDates }: TripDatesContentProps) => {
    if (!surveyDates) {
        return (
            <WidgetLoadingFallback
                skeletonLoaderHeight='full'
                skeletonLoaderWidth='full'
            />
        );
    }

    const datesChartData = surveyDates.map((dateResponse) => ({
        label: dateResponse.month,
        value: dateResponse.responses,
    }));

    const maxValue = findMaxChartValue(datesChartData);

    return (
        <VerticalBarChart
            max={maxValue}
            height='full'
            colorScheme='teal'
            chartData={datesChartData}
        />
    );
};

const TripDates = () => {
    const progressContext = useHomeProgressContext();

    if (!progressContext) {
        return null;
    }

    const {
        data: {
            reviewData: { data },
        },
    } = progressContext;

    const trackingEvent: TrackEventParameters = {
        eventName: 'Host Reviewed Dates',
        properties: { months: data?.travelDates },
    };

    return (
        <ReviewBaseStep
            stepKey={ProgressStepKeys.TRAVEL_DATES}
            labels={labels}
            customContent={
                <BaseBox height={{ base: '36', md: '44' }} maxWidth='full'>
                    <TripDatesContent surveyDates={data?.travelDates} />
                </BaseBox>
            }
            trackingEvent={trackingEvent}
        />
    );
};

export default TripDates;
