import { Heading, Image, Stack, Text } from '@trova-trip/trova-components';
import { BorderedInnerLinkCard } from '../../../dashboard/components/common/BorderedInnerCard';

interface DocumentationCardProps {
    imageSrc: string;
    heading: string;
    copy: string;
    onClick: () => void;
}

const IMAGE_DIMENSIONS = {
    width: { base: '98px', md: '119px' },
    height: { base: '70px', md: '85px' },
};

const DocumentationCard = ({
    imageSrc,
    heading,
    onClick,
    copy,
}: DocumentationCardProps): JSX.Element => {
    return (
        <BorderedInnerLinkCard onClick={onClick} padding={3}>
            <Stack wrap='nowrap' spacing={{ base: 2, md: 4 }}>
                <Image
                    data-gumlet='false'
                    src={imageSrc}
                    {...IMAGE_DIMENSIONS}
                />
                <Stack
                    direction='column'
                    spacing={{ base: 0, md: 2 }}
                    paddingY={{ md: 1 }}
                >
                    <Heading
                        as='h6'
                        fontWeight='medium'
                        fontSize={{ base: 'base', md: 'lg' }}
                        color='blueGray.800'
                    >
                        {heading}
                    </Heading>
                    <Text fontSize='sm' lineHeight='normal'>
                        {copy}
                    </Text>
                </Stack>
            </Stack>
        </BorderedInnerLinkCard>
    );
};

export default DocumentationCard;
